const Err = require("_common/utils/src/error");

function notImplemented() {
	throw new Err('ISession is an abstract class and should not be instantiated directly.');
}
export default class ISession {
	getUser() { notImplemented(); }
	setLanguage() { notImplemented(); }
	getLanguage() { notImplemented(); }
	logOffUser(notify = true) { notImplemented(); }
	isLoggedIn() {notImplemented(); }
	clear() { notImplemented(); }
}
ISession.Event = {
	LOGIN: 'login',
	LOGOUT: 'logout'
};

/*
	ISession implementations should also fire LOGIN and LOGOUT events:
	this.fire(Session.Event.LOGIN, {user, token});
	this.fire(Session.Event.LOGOUT, user);
 */