/*
	DEPRECATED
	It is one large util object that includes lodash. We should cut it into smaller pieces and separate it from lodash.
	If you need a util from this file, please move it to utils/index.js or utils/{category}.js
 */

(function() {
	const Log = require('_common/core/src/log').instance('misc');

	const _ = require('_common/utils/index');

	const Utils = {};

	Utils.timer = {
		timers: {},

		start: function(name) {
			Utils.timer.timers[name] = {
				start: new Date()
			};
		},

		/**
		 *
		 * @param name
		 * @param {boolean} log
		 * @returns {number}
		 */
		stop: function(name, log) {
			var start = _.get(Utils.timer.timers[name], 'start');
			if(!(start instanceof Date)){
				return 0;
			}
			Utils.timer.timers[name].end = new Date();
			var time = Utils.timer.timers[name].end - Utils.timer.timers[name].start;
			if(log === true) {
				Log.logGeneric('log', ["Timer [" + name + "] took " + time + " ms."], {stackOffset: 1});
			}
			return time;
		}
	};

	Utils.compareIndex = require('_common/core/src/utils').compareIndex;

	Utils.parseValue = function(value) {
		var parsed = null;
		if(_.isArray(value)) {
			parsed = [];
			_.forEach(value, function(v, i) {
				parsed[i] = Utils.parseValue(v);
			});
		} else if(_.isObject(value)) {
			parsed = {};
			for(var i in value) {
				parsed[i] = Utils.parseValue(value[i]);
			}
		} else if (_.isString(value)) {
			// Here we can add more string parsing (e.g. for objects and arrays)
			if(_.isNumeric(value)) {
				parsed = parseFloat(value);
			} else if((p = Utils.parseBoolean(value, true)) !== null) {
				parsed = p;
			} else if (value === '') {
				parsed = undefined;
			} else {
				parsed = value;
			}
		} else {
			parsed = value;
		}
		return parsed;
	};

	Utils.getFromjQueryCollection = function(elements, method, args) {
		if(!_.isString(method) || !_.isFunction(elements[method])) {
			Log.warn("Second parameter must be name of jQuery method.");
			return [];
		}
		if(args === undefined) {
			args = [];
		}
		if(!_.isArray(args)) {
			Log.warn("Third parameter must be array or undefined.");
			return [];
		}

		var result = [];
		elements.each(function(index, element) {
			result.push($(element)[method].apply($(element), args));
		});
		return result;
	};

	/**
	 * Checks if both given arrays have the same contents
	 * @param array1
	 * @param array2
	 * @returns {boolean}
	 */
	Utils.arraysHaveSameContents = function(array1, array2) {
		if(!_.isArray(array1) || !_.isArray(array2)) {
			Log.warn('array1,array2', [array1,array2], "Must be arrays");
			return false;
		}
		if(array1.length != array2.length) {
			return false;
		}

		for(var i in array1) {
			if(array2.indexOf(array1[i]) < 0) {
				return false;
			}
		}
		return true;
	};

	Utils.parseBoolean = function(string, strict) {
		if(strict && [true, false, 1, 0, 'true', 'false'].indexOf(string) < 0) {
			return null;
		}
		return string === true || string.toLowerCase() === 'true' || string === 1;
	};

	Utils.parseArray = function(string) {
		if(_.isArray(string)) {
			return string;
		}
		if(!_.isString(string)) {
			Log.warn("Cannot parse argument to array.", string);
			return [];
		}
		if(string === "") {
			return [];
		}

		// Parse array
		var arr = string.split(',');
		for(var i in arr) {
			arr[i] = arr[i].trim();
		}
		return arr;
	};

	/**
	 * Joins path segments.  Preserves initial "/" and resolves ".." and "."
	 * Does not support using ".." to go above/outside the root.
	 * This means that join("foo", "../../bar") will not resolve to "../bar"
	 *
	 * Source: https://gist.github.com/creationix/7435851
	 * @returns {string}
	 */
	Utils.joinPath = function(/* path segments */) {
		// Split the inputs into a list of path commands.
		var parts = [];
		for (var i = 0, l = arguments.length; i < l; i++) {
			parts = parts.concat(arguments[i].split("/"));
		}
		// Interpret the path commands to get the new resolved path.
		var newParts = [];
		for (i = 0, l = parts.length; i < l; i++) {
			var part = parts[i];
			// Remove leading and trailing slashes
			// Also remove "." segments
			if (!part || part === ".") continue;
			// Interpret ".." to pop the last segment
			if (part === "..") newParts.pop();
			// Push new path segments.
			else newParts.push(part);
		}
		// Preserve the initial slash if there was one.
		if (arguments[0].substr(0,1) === "/") newParts.unshift("");
		// Preserve slash in protocol if there was one
		if (/:$/.exec(parts[0]) !== null) {
			newParts[0] += '/';
		}

		// Turn back into a single string path.
		return newParts.join("/") || (newParts.length ? "/" : ".");
	};

	/**
	 * Maps properties/functions from one object to the other.
	 * @param {object} from The object from which to read the properties/functions.
	 * @param {object} to The object to which to write the properties/functions.
	 * @param {object} conversion A conversion map with property/function names of
	 * the 'from' object as keys, and property/function names of the 'to' object as
	 * values.
	 * @param {function} filter A filter function that takes the value of a property/function
	 * and return whether it should be mapped (TRUE) or not (FALSE).
	 * @returns {undefined}
	 */
	Utils.mapProperties = function(from, to, conversion, filter) {
		for(var i in conversion) {
			if(_.has(from, i) &&
				(!_.isFunction(filter) || filter(from[i]))) {
				to[conversion[i]] = from[i];
			}
		}
	};

	/**
	 * Merges an object into another object (by reference), setting values by path
	 * rather than replacing entire branches. If, at any level, an object should replace the entire branch rather than
	 * be merged into it, add a `_replace: true` property to it.
	 * @param {object} obj
	 * @param {object} into
	 * @param {string} [replacementProperty]	A property that can be used to replace a branch instead of merge it.
	 * 											E.g. set this to `_replace`, then set `_replace: true` on a branch of
	 * 											the obj parameter, and when it gets to that object, the branch will be
	 * 											replaced instead of merged.
	 * @returns array Array of changed paths
	 */
	Utils.mergeObjectInto = function(obj, into, replacementProperty) {
		if(!_.isObject(obj) || !_.isObject(into)) {
			Log.warn("First parameter must be object.", obj, into);
			return false;
		}

		let changes = {};
		for(let i in obj) {
			if(_.isObject(obj[i]) && _.isObject(into[i])) {
				// Array will be replaced, as well as objects with the replacementProperty
				if(_.isArray(obj[i]) || (_.isString(replacementProperty) && obj[i][replacementProperty] === true)) {
					delete obj[i][replacementProperty];
					// Only replace if different
					if(!_.isEqual(into[i], obj[i])) {
						changes[i] = {new: obj[i], old: _.cloneDeep(into[i])};
						into[i] = obj[i];
					}
				} else {
					// Merge recursively
					let subChanges = Utils.mergeObjectInto(obj[i], into[i], replacementProperty);
					_.forEach(subChanges, (change, subPath) => {
						changes[i + '.' + subPath] = change;
					})
				}
			} else {
				let value = _.cloneDeep(obj[i]);
				Utils.removePropertyWithName(value, replacementProperty);
				// Only replace if different
				if(!_.isEqual(into[i], value)) {
					changes[i] = {new: obj[i], old: _.cloneDeep(into[i])};
					into[i] = value;
				}
			}
		}
		return changes;
	};

	/**
	 * Set defaults by their paths in the data.
	 *
	 * @param {object} data
	 * @param {object} defaults
	 */
	Utils.deepDefaults = function(data, defaults) {
		for(let i in defaults) {
			if(i in data) {
				// Data is set, but is object. Merge deeper.
				if(_.isObject(data[i]) && _.isObject(defaults[i])) {
					Utils.deepDefaults(data[i], defaults[i]);
				}
			} else {
				// Data not set yet, set default
				data[i] = defaults[i];
			}
		}
	};

	/**
	 * Removes property path from the object.
	 * @param obj
	 * @param paths
	 */
	Utils.removeObjectPath = function(obj, path) {
		if (! _.isObject(obj)) {
			return;
		}

		if(_.isString(path)) {
			path = path.split('.');
		}
		if(!_.isArray(path) || path.length === 0) {
			return;
		}
		if(path.length === 1) {
			delete obj[path[0]];
		} else {
			var next = path.shift();
			Utils.removeObjectPath(obj[next], path);
		}
	};

	/**
	 * Check if value is defined (not null or undefined).
	 * @param value
	 * @return boolean
	 */
	Utils.def = function(value) {
		return value !== null && value !== undefined;
	};

	/**
	 * Checks whether the given object has a certain set of properties set equal to a given specification (checks
	 * recursively).
	 *
	 * @param object
	 * @param specification
	 * @returns {boolean}
	 */
	Utils.matchesSpecification = function(object, specification) {
		if(!_.isObject(object) || !_.isObject(specification)) {
			return _.isEqual(object, specification);
		}

		for(var i in specification) {
			// i can be path
			var child = _.get(object, i);
			var propMatch = Utils.matchesSpecification(child, specification[i]);
			if(!propMatch) {
				return false;
			}
		}
		return true;
	};


	/**
	 * Creates a table out of several arrays.
	 * @param {object} data The keys of this object will be used as the keys
	 * for each column. The arrays will be used for the values.
	 * @returns {object}
	 */
	Utils.transpose = function(data) {
		var table = [];
		for(var col in data) {
			for(var row in data[col]) {
				if(table[row] === undefined) {
					table[row] = {};
				}
				table[row][col] = data[col][row];
			}
		}
		return table;
	};

	Utils.isBooleanParsable = function(variable) {
		return [true, false, 'true', 'false', 1, 0].indexOf(variable) >= 0;
	};
	_.setValidationMethod('isBooleanParsable', Utils.isBooleanParsable, "Invalid boolean value (true/false/0/1).");

	Utils.isNumeric = function(value) {
		if (_.isArray(value)) {
			return false;
		}

		if (_.includes([null, undefined, ''], value)) {
			return false;
		}

		return !isNaN(value);
	};
	_.setValidationMethod('isNumeric', Utils.isNumeric, "Invalid numeric value.");

	/**
	 * Tests whether the variable has a value that can be considered equal to the given boolean value.
	 * @param variable			  The variable to test.
	 * @param {boolean} value	   The boolean value to test with.
	 */
	Utils.hasBooleanValue = function(variable, value) {
		var booleanTrue = variable === true || variable === 'true' || variable === 1 || variable === "1";
		var booleanFalse = variable === false || variable === 'false' || variable === 0 || variable === "0";
		return value ? booleanTrue : booleanFalse;
	};

	/**
	 * Checks if the given subset of key-value pairs exists in the given object.
	 * @param {object} obj
	 * @param {object} subset
	 */
	Utils.hasObjectValues = function(obj, subset) {
		if(!_.isObject(obj)) {
			return false;
		}
		if(!_.isObject(subset)) {
			return true;
		}

		for(var key in subset) {
			if(!_.isEqual(obj[key], subset[key])) {
				return false;
			}
		}

		return true;
	};

	/**
	 * Returns an array of possible paths of properties in the object.
	 * @param obj
	 * @returns {string[]}
	 */
	Utils.getPaths = function (obj) {
		if (!_.isObject(obj)) {
			return [];
		}
		var paths = [];
		for (var i in obj) {
			var subPaths = Utils.getPaths(obj[i]);
			if(subPaths.length === 0) { // only add paths to end nodes
				paths.push(i);
			} else {
				_.forEach(subPaths, function(subPath, spIndex) {
					paths.push(i + '.' + subPaths[spIndex]);
				});
			}
		}
		return paths;
	};

	/**
	 * Finds the longest valid part of a given path into an object.
	 *
	 * Example:
	 *
	 * var obj = {
	 * 		foo: {
	 * 			bar: 123
	 * 		}
	 * };
	 * Utils.deepestValidPath(obj, 'foo.qux'); // would return 'foo'.
	 *
	 * @param obj
	 * @param path
	 * @returns {*}
	 */
	Utils.deepestValidPath = function(obj, path) {
		if(_.isString(path)) {
			path = path.split('.');
		}

		if(path.length > 0 && obj.hasOwnProperty(path[0])) {
			var step = path.shift();
			var rest = Utils.deepestValidPath(obj[step], path);
			var returnPath = step;
			if(rest !== null) {
				returnPath += '.' + rest;
			}
		} else {
			return null;
		}
		return returnPath;
	};

	Utils.concatSafe = function(array1, array2) {
		if(!Utils.def(array1) && !Utils.def(array2)) {
			return [];
		}

		// One of them is defined
		if(_.isArray(array1) && !Utils.def(array2)) {
			return array1;
		}
		if(_.isArray(array2) && !Utils.def(array1)) {
			return array2;
		}

		// They are both defined
		if(!_.isArray(array1)) {
			array1 = [array1];
		}
		if(!_.isArray(array2)) {
			array2 = [array2];
		}

		return array1.concat(array2);
	};

	/**
	 * Check if two arrays are equal, not in their reference but in the references/values of their items.
	 * @param {Array} array1
	 * @param {Array} array2
	 * @param {function} [itemEqualityFunc]		The function to test the equality of two items of the array.
	 * @returns {boolean}
	 */
	Utils.isEqualArray = function(array1, array2, itemEqualityFunc) {
		if(!_.isArray(array1) || !_.isArray(array2)) {
			return false;
		}
		if(array1.length !== array2.length) {
			return false;
		}
		if(!_.isFunction(itemEqualityFunc)) {
			itemEqualityFunc = function(item1, item2) { return item1 === item2; };
		}

		for(var i = 0; i < array1.length; i++) {
			if(!itemEqualityFunc(array1[i], array2[i])) {
				return false;
			}
		}
		return true;
	};

	/**
	 * Converts the given paths in the data to arrays if they are objects.
	 * @param {object} data
	 * @param {array} paths
	 */
	Utils.objectsToArrays = function(data, paths) {
		_.forEach(paths, (path) => {
			let value = _.get(data, path);
			if(_.isPlainObject(value)) {
				_.set(data, path, _.values(value));
			}
		});
		return data;
	};

	/**
	 * Recursively removes all properties with the given name from the given object.
	 * @param {object} obj
	 * @param property
	 */
	Utils.removePropertyWithName = function(obj, property) {
		if(!_.isObject(obj)) {
			return;
		}
		for(var i in obj) {
			if(i === property) {
				delete obj[i];
			} else if (_.isObject(obj[i])) {
				Utils.removePropertyWithName(obj[i], property);
			}
		}
	};
	/**
	 * Unfinished, but finds all additions to and removals from a collection, based on the equalsFunc. Order not important.
	 * @param {Array} from
	 * @param {Array} to
	 * @param {function} equalsFunc
	 */
	Utils.collectionDiff = function(from, to, equalsFunc) {
		var add = [];
		var remove = [];

		if ( ! equalsFunc ) {
			equalsFunc = function(value1, value2) {
				if(value1 === value2) {
					return true;
				} else if (_.isObject(value1) && _.isObject(value2) && 'id' in value1 && 'id' in value2) {
					return Utils.def(value1.id) && value1.id === value2.id;
				}
				return _.isEqual(value1, value2);
			};
		}

		_.forEach(to, function(item) {
			var found = _.find(from, function(fromItem) {
				return equalsFunc(item, fromItem);
			});
			// If item was not found in 'from' set, add to 'add' set.
			if(found === undefined) {
				add.push(item);
			}
		});
		_.forEach(from, function(item) {
			var found = _.find(to, function(fromItem) {
				return equalsFunc(item, fromItem);
			});
			// If item was not found in 'to' set, add to 'remove' set.
			if(found === undefined) {
				remove.push(item);
			}
		});

		return {
			changed: add.length > 0 || remove.length > 0,
			add: add,
			remove: remove
		};
	};

	/**
	 * Recursively removes all properties with the given name from the given object.
	 * @param {object} obj
	 * @param property
	 */
	Utils.removePropertyWithName = function(obj, property) {
		if(!_.isObject(obj)) {
			return;
		}
		for(var i in obj) {
			if(i === property) {
				delete obj[i];
			} else if (_.isObject(obj[i])) {
				Utils.removePropertyWithName(obj[i], property);
			}
		}
	};

	Utils.isError = function(value) {
		return value instanceof Error;
	};

	Utils.require = function(namespace, dependencies) {
		if(typeof dependencies === 'string') {
			dependencies = [dependencies];
		}
		if(!(dependencies instanceof Array)) {
			console.error("Invalid dependency array. Cannot check dependencies.", dependencies);
			return false;
		}
		if(typeof namespace !== 'string') {
			console.error("Invalid namespace name. Cannot check dependencies.", namespace);
			return false;
		}
		if(typeof window[namespace] !== 'object') {
			console.error("'" + namespace + "' is not a namespace.");
			return false;
		}

		// Avoid dependency of lodash
		var __get = function(obj, path) {
			if(typeof path === 'string') {
				path = path.split('.');
			}
			if(path.length === 0) {
				return obj;
			}

			var step = path.shift();
			if(!(step in obj)) {
				return undefined;
			}

			return __get(obj[step], path);
		};

		var missing = [];
		for(var i = 0; i < dependencies.length; i++) {
			var dependency = __get(window[namespace], dependencies[i]);
			if(typeof dependency !== 'function') {
				missing.push(dependencies[i]);
			}
		}

		if(missing.length > 0) {
			console.error("Missing dependencies in '" + namespace + "' namespace:", missing);
			return false;
		}

		return true;
	};

	Utils.downloadAsFile = function(filename, text){
		// Set up the link
		var link = document.createElement("a");
		link.setAttribute("target","_blank");
		if(Blob !== undefined) {
			var blob = new Blob([text], {type: "text/plain"});
			link.setAttribute("href", URL.createObjectURL(blob));
		} else {
			link.setAttribute("href","data:text/plain," + encodeURIComponent(text));
		}
		link.setAttribute("download",filename);
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};


	Utils.getAllStyleSheets = function() {
		var allStyleSheets = [];

		var styleSheets = document.styleSheets;

		if (! styleSheets || ! styleSheets['length']) {
			return allStyleSheets;
		}

		// Does not return stylesheets loaded from external sources
		// For external sources start chrome with "--disable-web-security --user-data-dir" options
		var parseStyleSheetForImportedStyleSheets = function(styleSheet) {
			if (! (styleSheet instanceof CSSStyleSheet)) {
				return Utils.withError('getCSSForElement: styleSheet is invalid', styleSheet);
			}

			allStyleSheets.push(styleSheet);

			if (! styleSheet['cssRules'] || ! styleSheet.cssRules['length']) {
				return;
			}

			_.forEach(styleSheet.cssRules, function(cssRule) {
				if (! cssRule || ! cssRule['styleSheet']) {
					return;
				}

				parseStyleSheetForImportedStyleSheets(cssRule.styleSheet);
			});
		};

		_.forEach(styleSheets, parseStyleSheetForImportedStyleSheets);

		return allStyleSheets;
	};

	// returns all css definitions used by an element
	Utils.getCSSForElement = function(domElement) {
		var css = '';
		var styleSheets = Utils.getAllStyleSheets();

		if (! styleSheets || ! styleSheets['length']) {
			return css;
		}

		_.forEach(styleSheets, function(styleSheet) {
			if (! styleSheet['cssRules'] || ! styleSheet.cssRules['length']) {
				return;
			}

			_.forEach(styleSheet.cssRules, function(cssRule) {
				if (! cssRule || ! cssRule['cssText']) {
					return;
				}

				if (cssRule.cssText.match(/@font-face/)) {
					css += cssRule.cssText + "\n";
					return;
				}

				if (! cssRule['selectorText']){
					return;
				}

				var selectorMatchesElement = domElement.querySelector(cssRule.selectorText);

				if (! selectorMatchesElement) {
					return;
				}

				css += cssRule.cssText + "\n";
			});
		});
		return css;
	};

	/**
	 *replaces url("../font.fnt") with url("http://hostname.com/fonts/font.fnt")
	 */
	Utils.replaceRelativeWithAbsoluteUrlsInString = function(sourceString) {
		var regexForAll = /url\([\"]?([^\"\(\)]*)[\"]?\)/gi;
		var regexForSingle = /url\([\"]?([^\"\(\)]*)[\"]?\)/i;
		var urls = sourceString.match(regexForAll);
		var href = document.createElement('a');
		var result = sourceString;

		_.forEach(urls, function(url) {
			var match = url.match(regexForSingle);
			if (! match || ! match['length']) {
				return;
			}

			href.href = match[1];
			//replace relative URL with absolute URL
			result = result.replace(match[1], href.href);
		});
		return result;
	};

	Utils.getSVGFileSourceFromElement = function(svgElement) {
		var deferred = $.Deferred();

		var svgFileContent = '\
<?xml version="1.0" encoding="utf-8" standalone="no"?>\
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">\
';
		var css = Utils.replaceRelativeWithAbsoluteUrlsInString(Utils.getCSSForElement(svgElement));

		var elementCopy = svgElement.cloneNode();
		elementCopy.innerHTML = svgElement.innerHTML;

		var attributes = {
			height: '100vh',
			width: '100%',
			version: "1.1",
			xmlns: "http://www.w3.org/2000/svg",
			'xmlns:xlink': "http://www.w3.org/1999/xlink"
		};

		_.forEach(attributes, function(value, attribute) {
			elementCopy.setAttribute(attribute, value);
		});

		var styleElement = document.createElement('style');
		styleElement.setAttribute('type', 'text/css');
		styleElement.innerHTML = "<![CDATA[\n" + css + "\n]]>";
		var defsElement = document.createElement('defs');
		defsElement.appendChild(styleElement);

		const svgpan = require('client/libraries/SVGPan/SVGPan.js.txt');
		var scriptElement = document.createElement('script');
		scriptElement.setAttribute('type', 'text/ecmascript');
		scriptElement.innerHTML = '<![CDATA[' + svgpan + ']]>';

		elementCopy.appendChild(scriptElement);

		elementCopy.insertBefore(defsElement, elementCopy.firstChild);
		svgFileContent += elementCopy.outerHTML;
		elementCopy.remove();

		deferred.resolve(svgFileContent);


		return deferred.promise();
	};

	Utils.isValidNode = function(node) {
		return 	_.isObject(node) &&
			_.has(node, 'id') &&
			_.has(node, 'labels') &&
			Utils.isSimpleValue(node.id);
	};

	Utils.isValidRelation = function(rel) {
		return 	_.isObject(rel) &&
				_.has(rel, 'id') &&
				_.has(rel, 'type') &&
			Utils.isSimpleValue(rel.id);
	};

	Utils.isUrlPath = function(string) {
		if (! _.isString(string)) {
			return false;
		}
		const regex = /\/file\/.*\/.*\..*/;

		return string.match(regex) !== null;
	};

	Utils.isUrl = function(string) {
		if (! _.isString(string)) {
			return false;
		}

		if (string.indexOf('http://') == 0) {
			return true;
		}

		if (string.indexOf('https://') == 0) {
			return true;
		}

		return false;
	};

	Utils.toJSON = function(...params) {
		var result = "";

		try {
			result = JSON.stringify(...params);
		}
		catch (ex) {
			Log.error('JSON stringify exception', ex.toString());
		}

		return result;
	};

	Utils.fromJSON = function(jsonString) {
		var result = jsonString;

		try {
			result = JSON.parse(jsonString);
		}
		catch (ex) {
			Log.error('JSON parse exception', ex.toString());
		}

		return result;
	};

	Utils.flattenObject = function(object) {
		if(!_.isObject(object)) {
			return {};
		}

		var flattened = {};
		function _flatten(object, path) {
			if(!_.isObject(object)) {
				flattened[path.join('.')] = object;
				return;
			}
			for(var key in object) {
				var newPath = _.clone(path);
				newPath.push(key);
				_flatten(object[key], newPath);
			}
		}

		_flatten(object, [], flattened);

		return flattened;
	};

	Utils.isoDate = function(value) {
		let date = (_.isDate(value) && value) 
				|| (_.isNil(value) && new Date()) // in Chrome new Date(undefined) => Invalid date
				|| new Date(value);

		let parts = {
			year: date.getFullYear(),
			month: _.padStart(date.getMonth() + 1, 2, '0'),
			day: _.padStart(date.getDate(), 2, '0')
		}

		return `${parts.year}-${parts.month}-${parts.day}`;
	};

	Utils.isCyclic = function(obj) {
		var path = [];
		var stack = [];

		function detect (obj, objKey) {

			var itIsCyclic = false;

			stack.push(objKey);
			path.push(obj);


			_.forOwn(obj, function(value, key) {
				if (!_.isObject(value)) {
					return;
				}
				if (_.find(path, function(object) {
					if (value === object) {
						return true;
					}
					return false;
				})) {
					itIsCyclic = true;
					return false;
				}
			});

			if (itIsCyclic) {
				return itIsCyclic;
			}

			_.forOwn(obj, function(value, key) {
				if (!_.isObject(value)) {
					return;
				}
				if (detect(value, key)) {
					itIsCyclic = true;
					return false;
				}
			});

			stack.pop();
			path.pop();

			return itIsCyclic;
		}

		return detect(obj, 'ROOT');
	};

	Utils.parsePrimitiveValues = function(object) {
		if (!_.isObject(object)) {
			return object;
		}

		if (object instanceof Number) {
			return object.valueOf();
		}

		_.forEach(object, function(value, key) {
			object[key] = Utils.parsePrimitiveValues(value);
		});

		return object;
	};

	Utils.isSimpleValue = function(value) {
		if (_.isFinite(value) || _.isString(value)) {
			return true;
		}

		return false;
	};

	Utils.ensureIsArray = function(array) {
		if(!_.def(array)) {
			return [];
		}
		if (!_.isArray(array)) {
			array = [array];
		}
		return array;
	};

	// if value is undefined returns defaultValue else returns undefined
	Utils.resolve = function(value, defaultValue) {
		if (value === undefined) {
			return defaultValue;
		}

		return value;
	};

	// for _.includes() '1' !== 1, this function treats them the same
	Utils.includesLoose = function(array, item, fromIndex) {
		if (_.isObject(item) || _.isArray(item)) {
			return _.includes(array, item, fromIndex);
		}

		var intItem = parseInt(item, 10);
		var strItem = item.toString();

		var result = _.includes(array, strItem, fromIndex);

		if (!result) {
			result = _.includes(array, intItem, fromIndex);
		}

		return result;
	};

	Utils.ensureTrailingSlash = function(string) {
		if(string.slice(-1) !== '/') {
			string += "/";
		}

		return string;
	};

	/**
	 * Checks if an "object" has a properties and property types within a given "structure"
	 * "object" can be a differend type than Object in which case it's type must match that of "structure"
	 * "object" must have all "structure" properties and the "object" properties must have specified types
	 * If a "structure" property value is undefined type checking is not performed so the object.property can have any type as long as it is present
	 * If "strict" is false "object" is not required to have all the properties from "structure"
	 */
	Utils.hasStructure = function(object, structure, strict) {
		strict = Utils.resolve(strict, true);

		if (! _.isObject(structure)) {
			return (typeof(structure) === typeof(object));
		}
		if (! _.isObject(object)) {
			return false;
		}

		var result = true;

		_.forOwn(structure, function(value, key) {
			if (! _.has(object, key)){
				if (! strict) {
					return;
				}
				result = false;
				return Utils.withError(['hasStructure: property \'' + key + '\' is missing in object', object,'for stucture', structure]);
			}

			if (value === undefined) {
				return;
			}

			if (typeof(object[key]) !== typeof(value)) {
				result = false;
				return  Utils.withError(['hasStructure: property \'' + key + '\' must be of type ' + typeof(value) + ' in object', object, 'for stucture',structure]);
			}

			if (_.isObject(value) && ! _.isEmpty(value) && ! hasStructure(object[key], value, strict)) {
				result = false;
				return false;
			}

		});

		return result;
	};

	/**
	 * Log error message and return error value; you can use this like
	 * return Utils.withError('someMessage']) -> will log message and return false
	 * return Utils.withError(['someMessage', someVar], ) will log the array and return someVar
	 */
	Utils.withError = function(errorParameters, errorValueToReturn) {

		if (_.isArray(errorParameters)) {
			console.error.apply(console, errorParameters);
		}
		else {
			console.error(errorParameters);
		}
		return Utils.resolve(errorValueToReturn, false);
	};

	Utils.isNumeric = function(value) {
		if ((value > 0) || (value < 0)) {
			return true;
		}

		if ((value === null) || (value === undefined)) {
			return false;
		}

		// string with only spaces is == 0
		if (! value.toString().trim().length) {
			return false;
		}

		if (value == 0) {
			return true;
		}

		return false;
	};

	Utils.isObjectPath = function (key) {
		if (!_.isString(key)) {
			return false;
		}

		if (/^([\$\#]*[a-z_]+[a-z0-9_]*)(\.[a-z_\$]+[a-z0-9_]*)*$/i.test(key)) {
			return true;
		}

		return false;
	};

	Utils.setValueForObjectPath = function (object, path, value) {
		if (!_.isObject(object)) {
			Log.error('Utils.setValueForObjectPath: object parameter must be object', object);
			return object;
		}

		var result = object;

		if (!Utils.isObjectPath(path)) {
			Log.error('Utils.setValueForObjectPath: path parameter must be a valid object path', path);
			return result;
		}

		var iterator = result;
		var pathParts = path.split('.');

		// Remove last element
		var lastPathElement = pathParts.splice(-1);

		// Check/create path
		_.forEach(pathParts, function (pathElement) {
			if (!_.has(iterator, pathElement) || !_.isObject(iterator[pathElement])) {
				iterator[pathElement] = {};
			}

			iterator = iterator[pathElement];
		});

		// Ensure lastPathElement exists
		if (!_.has(iterator, lastPathElement)) {
			iterator[lastPathElement] = undefined;
		}

		// Assign value to last element
		if (value != undefined) {
			iterator[lastPathElement] = value;
		}

		return result;
	};

	Utils.ensureObjectPath = function (object, path) {
		return Utils.setValueForObjectPath(object, path);
	};

	Utils.valuesConsideredEqual = function(a,b) {
		function preprocess(v) {
			if(_.isNumeric(v) && !_.isBoolean(v)) { // boolean is also numeric..
				v = parseFloat(v);
			}
			if(_.isBooleanParsable(v)) {
				v = _.hasBooleanValue(v, true);
			}
			return v;
		}

		a = preprocess(a);
		b = preprocess(b);

		return _.isEqual(a,b);
	};

	Utils.addColumn = function(data, columnName, columnData) {
		const check = _.validate({
			data: [data, ['isObject'], "Data must be an array of objects."],
			columnName: [columnName, 'isString'],
			columnData: [columnData, 'isArray']
		});
		if(!check.isValid()) return [];
		const valid = check.getValue();

		const newData = _.cloneDeep(data);
		for(var i in valid.columnData) {
			_.set(newData, [i, valid.columnName], valid.columnData[i]);
		}

		return newData;
	};

	// Posts/Uploads a File to an URL, File is an item in the files property of an <input type="file"/>
	Utils.uploadFile = function(uploadUrl, file) {
		if (!(file instanceof File)) {
			return false;
		}

		var fileSize = file.size;
		var formData = new FormData();
		formData.append("file", file);

		var request = $.ajax({
			type: 'post',
			url: uploadUrl,
			data: formData,
			dataType: 'json',
			processData: false,
			contentType: false
		});

		return request;
	}

	var Neo4j = (() => {

		var toString = function(value) {
			return _.get(
				{
					string: _.identity,
					// not perfect, if properties/items are Functions the result will not be correct
					object: JSON.stringify, //object and array
				},
				typeof(value),
				_.toString //default
			)(value);
		}

		var escapeString =  function(str) {
			var result = _.replace(str, /\\/gi, '\\\\');
			result = _.replace(result, /'/gi, '\\\'');

			return result;
		}

		var keyValueToString = function(value, key) {
			return `\`${key}\`: '${escapeString(toString(value))}'`;
		}

		var quoteKey = function(string) {
			return '`' + string + '`';
		}

		var indent = (text) => {
			return '    ' + text;
		}

		var prependColon = (text) => {
			return ':' + text;
		}

		var labelsToString = function(labels) {
			return _.join(
					_.map(
						labels,
						_.flow([_.toString, _.trim, quoteKey, prependColon])
					),
					''
				);
		}

		var propertiesToString = function(properties) {
			return _.join(
					_.map(
						_.map(properties, keyValueToString),
						indent
					),
					',\n'
				);
		}

		return {
			quoteKey,
			labelsToString,
			propertiesToString
		}
	})();


	Utils.nodeToCreateStatement = function(node, addLabels = ['DUMP']) {
		if (! Utils.isValidNode(node)) {
			Log.error('nodeToString: node structure is invalid', node);
			return '';
		}

		// This ansures backwards compatibility
		// This function is called in the default Graphileon configuration like `_.map(nodes, nodeToCreateStatement)` so the second argument is set as node key 
		addLabels = _.isArray(addLabels) ? addLabels : ['DUMP'];

		var labels = Neo4j.labelsToString(_.concat(addLabels, node.labels));
		var properties = Neo4j.propertiesToString(node.properties);

		return `CREATE (_${node.id}${labels} {\n${properties}\n}) \n`;
	}


	Utils.relationToCreateStatement = function(relation) {
		if (! Utils.isValidRelation(relation)) {
			Log.error('relationToString: relation structure is invalid', relation);
			return '';
		}

		var properties = Neo4j.propertiesToString(relation.properties);

		return `CREATE (_${relation.source})-[:${Neo4j.quoteKey(relation.type)} {\n${properties}\n}]->(_${relation.target}) \n`;
	};


	/**
	 * Creates array of objects (rows) for a table from an array of objects (cells)
	 * @param {object} params - keys identifiers
	 * @param {object} params.rowKey - row identifier property of cell object
	 * @param {object} params.columnKey - column identifier property of cell object
	 * @param {object} params.valueKey - value property of cell object (optional)
	 * @param {array} data - Array of similar objects (table rows)
	 * @returns {array} - Array of pivot objects
	 *
		@example

		Utils.cellsToRows(
			{
				rowKey: 'row',
				columnKey: 'column',
				valueKey: 'value',
			},
			[
				{
					row: 1,
					column: 'name',
					value: 'Alex'
				},
				{
					row: 1,
					column: 'location',
					value: 'Bucharest'
				},
				{
					row: 2,
					column: 'name',
					value: 'Tom'
				},
				{
					row: 2,
					column: 'location',
					value: 'Amsterdam'
				}
			]
		)

		Returns:
			[
				{
					name: 'Alex',
					location: 'Bucharest'
				},
				{
					name: 'Tom',
					location: 'Amsterdam'
				}
			]

		@example
		Utils.cellsToRows(
			{
				rowKey: 'row',
				columnKey: 'column'
				// no valueKey
			},
			[
				{
					row: 1,
					column: 'name',
					value: 'Alex'
				},
				{
					row: 1,
					column: 'location',
					value: 'Bucharest'
				},
				{
					row: 2,
					column: 'name',
					value: 'Tom'
				},
				{
					row: 2,
					column: 'location',
					value: 'Amsterdam'
				}
			]
		)

		Returns:
			[
				{
					"row": {
						"_isPivot": true,
						"row": 1,
						"column": "name",
						"value": "Alex"
					},
					"name": {
						"_isPivot": false,
						"row": 1,
						"column": "name",
						"value": "Alex"
					},
					"location": {
						"_isPivot": false,
						"row": 1,
						"column": "location",
						"value": "Bucharest"
					}
				},
				{
					"row": {
						"_isPivot": true,
						"row": 2,
						"column": "name",
						"value": "Tom"
					},
					"name": {
						"_isPivot": false,
						"row": 2,
						"column": "name",
						"value": "Tom"
					},
					"location": {
						"_isPivot": false,
						"row": 2,
						"column": "location",
						"value": "Amsterdam"
					}
				}
			]

	 **/

	 Utils.cellsToRows = function(params, data) {
		var isVoid = function(value) {
			return _.isNil(value) || (value === "");
		}

		var isSimpleValue = function(value) {
			return (! isVoid(value)) && (_.isString(value) || _.isNumber(value));
		}

		var result = _.reduce(
			data,
			function(collector, record) {
				var rowKeyValue = _.get(record, params.rowKey);
				var columnKeyValue = _.get(record, params.columnKey);

				if (! isSimpleValue(rowKeyValue)) {
					return collector;
				}

				if (! isSimpleValue(columnKeyValue)) {
					return collector;
				}

				if (! _.isPlainObject(collector[rowKeyValue])) {
					collector[rowKeyValue] = {};
					collector[rowKeyValue][params.rowKey] =
						params.valueKey ?
						_.get(record, params.rowKey) :
						_.extend({_isPivot: true}, record);
				}

				collector[rowKeyValue][columnKeyValue] =
					_.get(
						record,
						params.valueKey,
						_.extend({_isPivot: false}, record)
					);

				return collector;
			},
			{}
		);

		return _.map(result, _.identity); //transform into Array
	};

	Utils.pivotDataToObjects = Utils.cellsToRows; // For backward compatibility

	/**
	 * Converts a simple array into a single-column table.
	 * @param {array} array		The simple array to convert.
	 * @param {string} column	The column name. Each object in the array will get that name as a key.
	 * @return {*}
	 */
	Utils.arrayToTable = function(array, column = 'value') {
		return _.map(array, item => {
			let row = {};
			row[column] = item;
			return row;
		});
	};

	// Extend general with _
	_.extendUtils(Utils, undefined, ['validate', 'validateOne', 'isError', 'def']);

	module.exports = _;
})();
