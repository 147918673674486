import _ from 'lodash';
import Log from 'utils/src/log';
import {checkType} from "utils/src/validation";
import Err from "utils/src/error";
import { deobfuscate } from 'core/src/utils/string';

const log = Log.instance("edition");

const buildFeatures = {};
let registeredEditions = {};
let DefaultEdition = undefined;

export default class Edition {
	static register(code, EditionClass) {
		if(!EditionClass.prototype instanceof Edition) {
			throw new Error("Provided class is not an Edition");
		}
		registeredEditions[code] = EditionClass;
	}
	static setDefaultEdition(EditionClass) {
		DefaultEdition = EditionClass;
	}
	static clearRegister() {
		registeredEditions = {};
	}
	static setBuildFeature(feature, value) {
		return buildFeatures[feature] = value;
	}

	static get code() { // not using `name` because that would override the native class name
		return 'standard'; // not an official Edition but it needs a name
	}

	static get name() {
		return 'Graphileon';
	}

	static get current() {
		return currentEdition;
	}

	static notAvailableError(feature) {
		return new Err({
			message: `${_.capitalize(feature)} is not available in edition '${Edition.current}.`,
			public: true
		});
	}

	static functionNotAvailableError(feature) {
		return new Err({
			message: `Function type '${_.capitalize(feature)}' is not available in edition '${Edition.current}'.`,
			public: true
		});
	}

	static hasFeature(feature, value) {
		return Edition.current.hasFeature(feature, value);
	}

	static exists(code) {
		return _.toLower(code) in registeredEditions;
	}
	static create(code) {
		if(!this.exists(code)) {
			log.error(`Unknown edition code '${code}'.`);
			if(!DefaultEdition) {
				log.error("Exiting.");
				process.exit(1);
			} else {
				log.error(`Falling back to ${DefaultEdition.name}...`);
				return new DefaultEdition();
			}
		}
		const RegisteredEdition = registeredEditions[_.toLower(code)];
		return new RegisteredEdition();
	}
	static setCurrent(edition) {
		if(_.isString(edition)) {
			edition = this.create(edition);
		}

		checkType(edition, Edition, 'edition');
		if(edition.code === currentEdition.code) {
			// Edition did not change
			return false;
		}

		// If the given edition allows more than the build, we cannot change to the given edition.
		if(!edition.isSupportedByBuild()) {
			log.error(`${edition} is not supported by build '${Edition.build}'.`);
			return;
		}
		currentEdition = edition;

		log.info(`Activated ${currentEdition.name} (${currentEdition.code}).`);
	}

	static getScreenName() {
		return `Graphileon ${_.upperCase(this.code)}`;
	}

	static getParentClass() {
		return Object.getPrototypeOf(this.prototype).constructor;
	}

	static async firstInHierarchy(callback, condition = value => !_.isNil(value)) {
		let result = await callback(this);
		if (!condition(result)) {
			const parent = this.getParentClass();
			if (parent.prototype instanceof Edition) {
				return await parent.firstInHierarchy(callback, condition);
			}
		}
		return null;
	}

	static async mapHierarchy(callback) {
		const result = await callback(this);
		const parent = this.getParentClass();
		if (!(parent.prototype instanceof Edition)) {
			return [result];
		}
		return _.concat(result, await parent.mapHierarchy(callback));
	}

	constructor() {
		this._licenseInfo = this.defineFeatures();
		this.name = this.constructor.name;
	}

	defineFeatures() {
		return {
			[Edition.Feature.USER_COUNT]: 0,
			[Edition.Feature.DASHBOARD_COUNT]: 0,
			[Edition.Feature.STORES_COUNT]: 0,
			[Edition.Feature.GRAPHILEONS_COUNT]: 0,
			[Edition.Feature.DEFAULT_STORE_COUNT]: 1,
			[Edition.Feature.EXPIRES]: true,
			[Edition.Feature.MANAGE_CONFIGURATION]: false,
			[Edition.Feature.CONFIGURATION_IMPORT_EXPORT]: false,
			[Edition.Feature.PERMISSION]: false,
			[Edition.Feature.FUNCTION_REQUEST]: false,
			[Edition.Feature.FUNCTION_API]: false,
			[Edition.Feature.PASSPORT_INTEGRATION]: false,
			[Edition.Feature.MULTI_LINGUAL]: false,
			[Edition.Feature.WEBSOCKET]: false,
			[Edition.Feature.FUNCTION_TIMER]: false,
			[Edition.Feature.FUNCTION_ITERATE]: false,
			[Edition.Feature.YFILES_READY]: false,
			[Edition.Feature.YFILES_LICENSE_AUTO]: false,
			[Edition.Feature.FUNCTION_ELASTICSEARCH]: false
		};
	}

	overrideFeature(feature, value) {
		this._licenseInfo[feature] = value;
	}

	is(EditionClass) {
		return this.constructor === EditionClass;
	}

	get code() {
		return this.constructor.code;
	}

	getScreenName() {
		return this.constructor.getScreenName();
	}

	getFeatures() {
		return this._licenseInfo;
	}

	getFeature(feature = null) {
		return this._licenseInfo[feature];
	}

	/**
	 * Determines whether this edition is supported by the current build.
	 * Any feature that requires code to be included in the build should have in the original file:
	 *
	 * ```
	 * Edition.setBuildFeature(Edition.Feature.{FEATURE}, false);
	 * ```
	 *
	 * and in the .feature.js file:
	 *
	 * ```
	 * Edition.setBuildFeature(Edition.Feature.{FEATURE}, true);
	 * ```
	 *
	 * @returns {boolean}
	 */
	isSupportedByBuild() {
		const findUnsupportedValue = _.find(buildFeatures, (value, feature) => {
			// If allowed value is higher than build value, current build cannot support it
			return this.getFeature(feature) > value;
		});
		return findUnsupportedValue === undefined;
	}

	hasFeature(feature, value = true) {
		return this.getFeature(feature) === value;
	}

	firstInHierarchy(callback, condition) {
		return this.constructor.firstInHierarchy(callback, condition);
	}

	mapHierarchy(callback) {
		return this.constructor.mapHierarchy(callback);
	}

	toString() {
		return this.getScreenName();
	}

	isValidYFilesLicense(license) {
		return false;
	}	
}
Edition.build = "default";

Edition.Feature = {
	FUNCTION_TIMER: 'FUNCTION_TIMER',
	FUNCTION_ITERATE: 'FUNCTION_ITERATE',
	FUNCTION_REQUEST: 'FUNCTION_REQUEST',
	FUNCTION_API: 'FUNCTION_API',
	FUNCTION_ELASTICSEARCH: 'FUNCTION_ELASTICSEARCH',

	USER_COUNT: 'USER_COUNT',
	DASHBOARD_COUNT: 'DASHBOARD_COUNT',
	STORES_COUNT: 'STORES_COUNT',
	GRAPHILEONS_COUNT: 'GRAPHILEONS_COUNT',
	DEFAULT_STORE_COUNT: 'DEFAULT_STORE_COUNT',
	EXPIRES: 'EXPIRES',

	MANAGE_CONFIGURATION: 'MANAGE_CONFIGURATION',
	CONFIGURATION_IMPORT_EXPORT: 'CONFIGURATION_IMPORT_EXPORT',
	PERMISSION: 'PERMISSION',
	PASSPORT_INTEGRATION: 'PASSPORT_INTEGRATION',
	MULTI_LINGUAL: 'MULTI_LINGUAL',
	WEBSOCKET: 'WEBSOCKET',
	YFILES_LICENSE_AUTO: 'YFILES_LICENSE_AUTO',
	YFILES_READY: 'YFILES_READY',
};
let currentEdition = new Edition();
