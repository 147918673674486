'use strict';

const LoginView = require('core/src/functions/views/login-view');
const VueRenderer = require('../vue-renderer');
const Login = require('./login.vue').default;
const IGraphileon = require('core/src/i-graphileon').default;

const LoginRenderer = function (dependencies) {
	VueRenderer.call(this, dependencies);
	this.graphileon = dependencies.get(IGraphileon);
};
LoginRenderer.viewType = 'LoginView';
LoginRenderer.prototype = Object.create(VueRenderer.prototype);

LoginRenderer.prototype.getVueComponent = function() {
	return Login;
};
LoginRenderer.prototype.initVueComponent = function(component) {
	const self = this;
	component.$on('submit', function(event) {
		self.event(LoginView.Event.In.SUBMIT, event);
	});
	component.$on('password-recover', function(event) {
		self.event(LoginView.Event.In.ACCOUNT_RECOVER, event);
	});
	this.graphileon.on(IGraphileon.Event.LOGIN, () => {
		this.close();
	});
};

module.exports = LoginRenderer;