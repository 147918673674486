export default function registerDefaultFunctions(factory, nodeSafe = false) {
	// Functions
	factory.register(require('./console'));
	factory.register(require('./cypher-query'));
	factory.register(require('./sparql-query'));
	factory.register(require('./gremlin-query'));
	factory.register(require('./execute-function'));
	factory.register(require('./load-diagram'));
	factory.register(require('./delete-node'));
	factory.register(require('./delete-relation'));
	factory.register(require('./io'));
	factory.register(require('./view'));
	factory.register(require('./timer'));
	factory.register(require('./html-inject'));
	factory.register(require('./custom-style'));
	factory.register(require('./api'));
	factory.register(require('./iterate'));
	factory.register(require('./event-listener'));
	factory.register(require('./elasticsearch-query'));
	factory.register(require('./save-diagram'));
	factory.register(require('./load-dashboard'));
	factory.register(require('./csv-parser'));
	factory.register(require('./navigate'));
	factory.register(require('./query'));
	factory.register(require('./user-management'));
	factory.register(require('./email'));
	factory.register(require('./node-management'));
	factory.register(require('./request'));
	factory.register(require('./data-management'));
	factory.register(require('./app-management'));
	factory.register(require('./preload-functions'));
	factory.register(require('./file-download'));
	factory.register(require('./pdf-file'));
}
