import G64Edition from "./g64t-edition";
import Edition from "./edition";

export default class EnterpriseEdition extends G64Edition {
	static get code() {
		return 'gee';
	}
	static get name() {
		return Edition.name + " Enterprise Edition";
	}
	defineFeatures() {
		return {
			...super.defineFeatures(),
			[Edition.Feature.EXPIRES]: true,
			[Edition.Feature.USER_COUNT]: null,
			[Edition.Feature.DASHBOARD_COUNT]: null,
			[Edition.Feature.STORES_COUNT]: null,
			[Edition.Feature.GRAPHILEONS_COUNT]: null,
			[Edition.Feature.FUNCTION_TIMER]: true,
			[Edition.Feature.FUNCTION_ITERATE]: true,
			[Edition.Feature.FUNCTION_API]: true,
			[Edition.Feature.PERMISSION]: true,
			[Edition.Feature.FUNCTION_REQUEST]: true,
			[Edition.Feature.PASSPORT_INTEGRATION]: true,
			[Edition.Feature.MULTI_LINGUAL]: true,
			[Edition.Feature.WEBSOCKET]: true,
			[Edition.Feature.YFILES_READY]: true,
			[Edition.Feature.CONFIGURATION_IMPORT_EXPORT]: true,
		};
	}
}
