const Log = require('core/src/log');
const _ = require('core/src/utils/legacy');
const Language = require('core/src/language').default;
const ApplicationInfo = require('core/src/application-info').default;
const ISession = require('core/src/i-session').default;
const $ = require('jquery');
const {isRunningNodeJs} = require("core/src/utils/platform");

const log = Log.instance("core/fti");

// Function Trigger Interface
const FTI = function(dependencies) {
	const self = this;
	this.appInfo = dependencies.get(ApplicationInfo);
	this.session = dependencies.get(ISession);
	this.language = dependencies.get(Language);

	this._listeners = {};
	this._instances = {};
	this._globalData = {};

	if (! isRunningNodeJs()) {
		$(document).on('mousemove', function(e) {
			self._globalData.mouse = {
				x: e.pageX,
				y: e.pageY
			};
		});
	}
};

/* Properties */

/**
 * @type Array Registered listeners
 */
FTI.prototype._listeners = undefined;
/**
 * Currently active Function instances. These instances can still fire events
 * and trigger connected Functions.
 * @type Array
 */
FTI.prototype._instances = undefined;

/**
 * Data that can be accessed from all Functions and Triggers.
 * @type {object}
 * @private
 */
FTI.prototype._globalData = undefined;

FTI.prototype._apiKeys = undefined;

/* Methods */

FTI.prototype.getGlobalData = function() {
	const instanceModels = this._getInstanceData();

	const user = this.session.getUser();

	let userData = user && _.extend({}, 
		user.properties, 
		{
			id: user.id,
			name: user.properties.name,
			permissions: user.permissions
		}
	);

	const global = {
		instances: instanceModels,
		version: this.appInfo.version,
		configurationVersion: this.appInfo.configurationVersion,
		revision: this.appInfo.revision,
		language: this.appInfo.language,
		languages: this.appInfo.languages,
		dashboard: this.appInfo.dashboard,
		backend: this.appInfo.backend,
		application: this.appInfo.application,
		stores: this.appInfo.stores,
		user: userData,
		edition: this.appInfo.edition,
		license: this.appInfo.license,
		apiKeys: this._apiKeys
	};
	_.extend(global, this._globalData);
	return global;
};

FTI.prototype.setGlobalData = function(key, value) {
	this._globalData[key] = value;
};

/**
 * Registers a Function instance with the FTI so it can listen to events addressed to it.
 * @param {Function} instance	 The Function instance to register.
 *										  will be registered. Defaults to false.
 * @returns {string}
 */
FTI.prototype.registerFunctionInstance = function(instance) {
	var check = _.validate({
		instance: [instance, instance instanceof Function, this.language.translate('Must be Function.')]
	}, this.language.translate('Could not register Function.'));
	if(!check.isValid()) {
		return null;
	}
	var valid = check.getValue();

	var instanceID = valid.instance.getInstanceID();
	if(!_.isStringOrNumber(instanceID)) {
		instanceID = _.uniqueId();
	}
	var existingInstance = this._instances[instanceID];
	if(existingInstance !== undefined && existingInstance !== instance) {
		var err = new _.Error({
			message: `FTI.registerFunctionInstance: ${this.language.translate("instance with instanceID '{{instanceID}}' was already registered: {{existingInstance}}", {instanceID, existingInstance})}`,
			data: {
				existingInstance: existingInstance
			}
		});
		return err;
	}

	this._instances[instanceID] = instance;
	return instanceID;
};

FTI.prototype.closeFunctionInstance = function(instanceID, origin) {
	if(instanceID in this._instances) {
		var instance = this._instances[instanceID];
		delete this._instances[instanceID];
		instance.closeFunctionInstance(false, origin);
	}
	return true;
};

FTI.prototype.closeAllFunctionInstances = function(lifespans) {
	for(var instanceID in this._instances) {
		var instance = this._instances[instanceID];

		// If a lifespans were specified, close only if instance has one of those lifespans
		if(_.isArray(lifespans)) {
			if(lifespans.indexOf(instance.getStayAlive()) >= 0) {
				this.closeFunctionInstance(instanceID);
			}
		} else {
			this.closeFunctionInstance(instanceID);
		}
	}
};

FTI.prototype.setDashboard = function(dashboard) {
	_.forEach(this._instances, instance => {
		instance.setDashboard(dashboard);
	});
};

FTI.prototype.getFunctionInstance = function(instanceID) {
	return this._instances[instanceID];
};

/**
 * Find Function instances that match the given search criteria.
 * @param {object} match				Search criteria
 * @param {string|number} match.id			 Function ID (not instance)
 * @param {string|number} match.instanceID	 Instance ID
 * @returns {{}}
 */
FTI.prototype.findFunctionInstances = function(match) {
	match = match || {};
	var results = {};
	for(var i in this._instances) {
		var f = this._instances[i];
		var isMatch = true;
		if(match.id !== undefined && f.getId() !== match.id) {
			isMatch = false;
		}
		if(match.instanceID !== undefined && f.getInstanceID() !== match.instanceID) {
			isMatch = false;
		}

		// Room for more criteria here
		// ...

		if(isMatch) {
			results[i] = f;
		}
	}
	return results;
};

/**
 * Updates Function instances matched by the given criteria, passing them the given data.
 * @param {object} match		Search criteria (see findFunctionInstances).
 * @param {object} data		 Data to pass to the Function instances.
 */
FTI.prototype.updateFunctionInstances = function(match, data) {
	const instances = this.findFunctionInstances(match);
	log.log("FTI Updating Instances matching", match, instances);
	let mutations = _.cloneDeep(data);

	// Update instances
	_.forEach(instances, instance => {
		instance.update(mutations);
	});
	return instances;
};

/**
 * Fire an event from an external source, targeting an existing Function instance.
 * @param {int|string} instanceID   The ID of the instance in which the event should be fired.
 * @param {string} event			The name of the event.
 * @param {object} data			 The data to pass with the event.
 * @returns {undefined}
 */
FTI.prototype.eventIn = function(instanceID, event, data) {
	var instance = this.getFunctionInstance(instanceID);
	if(!(instance instanceof Function)) {
		if(event !== Function.Event.In.CLOSE) { // don't warn on close - it was already closed
			log.warn("Event IN '" + event + "': instance '"+instanceID+"' is not known in FTI.");
		}
		return false;
	}
	instance.handleEvent(event, data);

	log.log("FTI event IN:", "Data: ", data, "Instance (" + instanceID +"):", instance);
};

/**
* Checks if there are listeners in FTI for an event
* @param  {string} event event name
* @return {boolean}
*/
FTI.prototype.eventOutHasListeners = function(event) {
	if(!_.isArray(this._listeners[event])) {
		return false;
	}
	return true;
};

/**
 * Fires an outgoing event, for external listeners.
 * @param {string} event		The event name
 * @param {object} data		 Any data to pass to the listeners.
 * @param {object} origin	   The origin of the event.
 * @returns {boolean}
 */
FTI.prototype.eventOut = function(event, data, origin) {
	var check = _.validate("eventOut", {
		event: [event, 'isString'],
		data: [data, 'isObject'],
		origin: [origin, 'isObject', {default: undefined, warn: origin !== undefined}]
	}, this.language.translate('Could not send event.'));
	if(!check.isValid()) return false;
	var valid = check.getValue();

	if (!this.eventOutHasListeners(valid.event)) {
		log.warn("No listeners for event " + valid.event);
		return false;
	}

	var originOutput = valid.origin;
	if(valid.origin !== undefined) {
		if(origin instanceof Function) {
			originOutput = {
				type: 'function',
				instanceID: origin.getInstanceID(),
				functionID: origin.getId()
			};
		} else if (origin instanceof Trigger) {
			originOutput = {
				type: 'trigger',
				triggerID: origin.id,
				targetFunction: origin.targetFunction
			};
		}
	}

	// Call all listeners for this event
	var count = 0;
	_.forEach( this._listeners[valid.event],  function(listener) {
		listener(valid.data, originOutput);
		count++;
	});

	log.log("FTI.eventOut " + count + " listeners:", "Event: ", valid.event, "Data: ", valid.data);
	return true;
};

/**
 * Adds an external listener for a specific event. It will be notified when the
 * event is fired.
 * @param {string} event The name of the event.
 * @param {object} listener A listener for the event. Should implement an 'on'
 * method, which is called with the name of the event and the data as parameters.
 * @returns {undefined}
 */
FTI.prototype.on = function (event, listener) {
	if(!_.isFunction(listener)) {
		log.warn("Listener should be a function.");
		return;
	}
	if(!_.isArray(this._listeners[event])) {
		this._listeners[event] = [];
	}
	this._listeners[event].push(listener);
};

/**
 * Feature: instance targeting
 *
 * Fetches current models of all open instances
 * @returns {{}}
 * @private
 */
FTI.prototype._getInstanceData = function() {
	const instanceModels = {};
	_.forEach(this._instances, function(instance) {
		if(!instance.isNamedInstance()) {
			return;
		}
		instanceModels[instance.getInstanceID()] = instance.readModel();
	});
	return instanceModels;
};

module.exports = FTI;

const Function = require('core/src/function');
const Trigger = require('core/src/trigger');
