
	import VueRendererComponent from "client/src/renderers/vue-components/vue-renderer-component.vue";
	
	export default {
		name: "network-vue-component",
		mixins: [VueRendererComponent],

		data: function() {
			return {
				username: undefined,
				password: undefined,
				error: undefined,
				loginProviders: [],
				accountRecovery: false
			};
		},

		methods: {
			submit() {
				this.$emit('submit', {
					username: this.username,
					password: this.password
				});
			},
			open(path) {
				let url = `${path}?success=/api/closewindow`
				window.localStorage.setItem('authWindow', url);
				window.open(url, url); 
			}
		}
	}
