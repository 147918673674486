const ViewContainer = require('client/src/view-container');
const _ = require('core/src/utils/legacy');
const onDOMElementsResized = require('client/src/utils/onDOMElementsResized')

const { jsPanel } = require('jspanel4/es6module/jspanel.min');
require('jspanel4/es6module/jspanel.min.css');
require('./container-content.scss');

const CONTAINER_SELECTOR = '#content[role="view-area"]';

const offsets = [];
const containerDefinition = {
	build: function(containerID, containerData, classes) {
		let height 	= _.get(containerData, 'height', 400);
		let width 	= _.get(containerData, 'width', 500);

		const nextOffset = {
			x: 0,
			y: 0
		};
		const inserted = _.find(offsets, function(panel, offset) {
			if(panel === undefined) {
				offsets[offset] = containerID;
				return true;
			}
			nextOffset.x += 30;
			nextOffset.y += 30;
		});
		if(!inserted) {
			offsets.push(containerID);
		}

		const config = {
			id: containerID,
			container: CONTAINER_SELECTOR,
			dragit: {
				containment: [0, undefined, undefined, 0]
			},
			resizeit: {
				containment:  [0, undefined, undefined, 0],
				stop: () => {
					this.resize();
				}
			},
			// By default jsPanels have an outside drag/resize border zone of 9px that is invisible
			// If the panel is maximized fully (without the bellow margin) this zone will cause scrollbars to appear on the containing div.
			maximizedMargin: [0, 10, 10, 0],
			contentSize: {width, height},
			theme: 'none',
			callback: function() {
				this.content.style.borderTop = 'none'; // because they set the border in the style itself
			},
			position: {
				my: 'left-top',
				at: 'left-top',
				offsetY: nextOffset.y,
				offsetX: nextOffset.x
			},
			onmaximized: () => {
				this.resize();
				this.updateState('state', ViewContainer.State.MAXIMIZED);
			},
			onnormalized: () => {
				this.resize();
				this.updateState('state', ViewContainer.State.NORMALIZED);
			},
			onminimized: () => {
				this.updateState('state', ViewContainer.State.MINIMIZED);
			},
			onsmallified: () => {
				this.updateState('state', ViewContainer.State.COLLAPSED);
			},
			onunsmallified: () => {
				if(this.$element[0].status === "maximized") {
					this.updateState('state', ViewContainer.State.MAXIMIZED);
				} else {
					this.updateState('state', ViewContainer.State.NORMALIZED);
				}
			},
			onclosed: () => {
				// set to prevent error on double closing
				this.jsPanelClosed = true;
				this.close('user');
			}
		};
		const panel = jsPanel.create(config);
		panel.id = containerID;

		this.containerResizeObserver = onDOMElementsResized(
			$(CONTAINER_SELECTOR),
			(ev) => {
				if (panel.status !== 'maximized') return;
				let oldZindex = panel.style.zIndex;
				panel.maximize();
				panel.style.zIndex = oldZindex;
			}
		);

		const div = $('<div class="container-content">');
		div.addClass(classes.join(' '));
		div.addClass('container-fixed-height');
		panel.content.appendChild(div[0]);

		this.classes = classes;

		return panel;
	},
	attachTo: function(area) {
		// Not necessary, jsPanel does that on build
	},
	setTitle: function(panel, title) {
		if(!_.def(title)) title = ' ';
		panel.setHeaderTitle(title);
	},
	close: function(panel) {
		this.containerResizeObserver.disconnect();

		// trigger remove on other views that are inside the panel so they close
		if (panel instanceof Element) {
			$(panel).find('.prologram-container').trigger('remove');
		}

		if (! this.jsPanelClosed) {
			panel.close();
		}

		_.find(offsets, function(containerID, offset) {
			if(containerID === panel.id) {
				offsets[offset] = undefined;
				return true;
			}
		});
	},
	setSize: function(panel, width, height) {
		// if we send width as `undefined` to the panel resizer, the method using the height for width.
		if ( width && height )
			panel.resize({width, height});
		else if ( width )
			panel.resize({width});
		else if ( height )
			panel.resize({height});
	},
	moveOnTop: function(panel) {
		panel.front();
	},
	moveOnBottom: function(panel) {
		if("minimized" === panel.status) {
			return;
		}

		panel.style.zIndex = jsPanel.ziBase;
		jsPanel.resetZi();
	},
	getInnerSize: function(panel) {
		if(!_.isObject(panel.content)) {
			return {width: undefined, height: undefined};
		}
		return {
			width: panel.content.clientWidth,
			height: panel.content.clientHeight
		};
	},
	setState: function(panel, state) {
		switch(state) {
			case ViewContainer.State.MAXIMIZED: panel.maximize(); break;
			case ViewContainer.State.MINIMIZED: panel.minimize(); break;
			case ViewContainer.State.COLLAPSED: panel.smallify(); break;
			default: panel.normalize(); break;
		}
	},
	focus: function(panel) {
		panel.front();
	}
};

module.exports = containerDefinition;
