const ViewRenderer = require('client/src/view-renderer');

const $ = require('jquery');
const visData = require('vis-data');
const visTimeline = require('vis-timeline');
const _ = require('core/src/utils/legacy');
const Moment = require('moment');

require('vis-timeline/dist/vis-timeline-graph2d.min.css');

const DOUBLE_CLICK_TIMEOUT_MS = 250;

function isTransposedArrayOfObjects(object) {
	// Parameter is plain object and each key has an array as its value
	return _.isPlainObject(object) && !_.findKey(object, value => !_.isArray(value));
}

const TimelineViewRenderer = function() {
	ViewRenderer.call(this);
	this._timeMarkers = {};
};
TimelineViewRenderer.viewType = 'TimelineView';
TimelineViewRenderer.prototype = Object.create(ViewRenderer.prototype);

/* Properties */

TimelineViewRenderer.prototype._timeline = null;
TimelineViewRenderer.prototype._data = null;
TimelineViewRenderer.prototype._dateFormat = 'YYYY-MM-DD';
TimelineViewRenderer.prototype._timeFormat = 'HH:mm:ss';
TimelineViewRenderer.prototype._datetimeFormat = 'YYYY-MM-DD HH:mm:ss';

function formatDate (date, dateFormat) {
	if (_.isDate(date)) {
		date = Moment(date).format(dateFormat);
	}
	return date;
};

function prepareEventData (item, dateFormat) {
	item = _.extend({}, item);
	if (dateFormat) {
		item.start = formatDate(item.start, dateFormat);
		item.end = formatDate(item.end, dateFormat);
	}
	return item;
};

/* Methods */

TimelineViewRenderer.prototype.getTimeline = function() {
	return this._timeline;
};

TimelineViewRenderer.prototype.getData = function() {
	return this._data;
};

TimelineViewRenderer.prototype.doResize = function() {
	this._timeline && this._timeline.redraw();
};

TimelineViewRenderer.prototype.doRender = function(data) {
	const self = this;

	let preventSingleClickEvent = false;

	const div = $('<div>');
	div.addClass('TimelineView');

	let events = data.events;
	let groups = data.groups;
	let options = _.extend(
		{},
		data.options,
		{
			onMove: (item) => {
				preventSingleClickEvent = true;
				item = prepareEventData(item, self._datetimeFormat);

				// Fire eventMoved after an update occurs on the state.
				// Otherwise it may fire when dragged and dropped on same place.
				self.requestUpdate({
					"_update.merge.events": [item],
				}).then((result)=> {
					if (_.isEmpty(result.changes)) {
						return;
					}
					self.userEvent({
						type: 'eventMoved',
						data: item
					});
				});
			}
		}
	)

	// Events were passed like this: { id: [1,2,3], title: ['a','b','c'], ...}
	if(isTransposedArrayOfObjects(events)) {
		events = _.transpose(events);
	} else {
		// Events were passed like this: { event1: {title: 'a'}, event1: {title:'b'}, ... }. Each event gets its id from the key.
		events = _.map(events, (event, key) => {
			return {id: key, ...event};
		});
	}
	// Groups were passed like this: { id: [1,2,3], content: ['a','b','c'], ...}
	if(isTransposedArrayOfObjects(groups)) {
		groups = _.transpose(groups);
	} else {
		// Groups were passed like this: { group1: {content: 'a'}, group2: {content:'b'}, ... }. Each group gets its id from the key.
		groups = _.map(groups, (group, key) => {
			return {id: key, ...group};
		});
	}

	// Data & Options
	this._data = new visData.DataSet(events);

	// Render the timeline when this.div is in the DOM
	_.defer(() => {
		// Create timeline
		// ISSUE: Current version of Vis-Timeline does not render the events when given as DataSet instance (must be investigated further)
		this._timeline = new visTimeline.Timeline(div.get()[0], events, groups, options);
		this.syncTimeMarkers(data.timeMarkers);

		// User events
		this._timeline.on('click', function(properties) {
			if (self._singleClickTimeout) {
				clearTimeout(self._singleClickTimeout);
			}
			if (preventSingleClickEvent) {
				preventSingleClickEvent = false;
				return;
			}
			self._singleClickTimeout = setTimeout(function() {

				if(_.isNil(properties.what) || (properties.what === 'background')) {
					const date = formatDate(properties.snappedTime, self._dateFormat);
					const time = formatDate(properties.snappedTime, self._timeFormat);
					const dateTime = formatDate(properties.snappedTime, self._datetimeFormat);
					self.userEvent({
						type: 'timelineClick',
						date: date,
						time: time,
						dateTime: dateTime,
						group: properties.group
					});
				}

				if (properties.what === 'group-label') {
					self.userEvent({
						type: 'groupClick',
						group: properties.group
					});
				}

				if (properties.what === 'item') {
					const items = self._data.get(properties.items[0]);
					let item = _.find(items, ['id', properties.item]);
					item = prepareEventData(item, self._datetimeFormat);
					self.userEvent({
						type: 'eventClick',
						data: item
					});
				}
				self._singleClickTimeout = undefined;
			}, DOUBLE_CLICK_TIMEOUT_MS);
		});
		this._timeline.on('doubleClick', function(properties) {
			if (self._singleClickTimeout) {
				clearTimeout(self._singleClickTimeout);
			}


			if(_.isNil(properties.what) || properties.what === 'background') {
				const date = formatDate(properties.snappedTime, self._dateFormat);
				const time = formatDate(properties.snappedTime, self._timeFormat);
				const dateTime = formatDate(properties.snappedTime, self._datetimeFormat);
				self.userEvent({
					type: 'timelineDoubleClick',
					date: date,
					time: time,
					dateTime: dateTime,
					group: properties.group
				});
			}
			if (properties.what === 'item') {
				let item = self._data.get(properties.item);
				item = prepareEventData(item, self._datetimeFormat);
				self.userEvent({
					type: 'eventDoubleClick',
					data: item
				});
			}
		});
		this._timeline.on('contextmenu', function(properties) {
			if(_.isNil(properties.what) || properties.what === 'background') {
				const date = formatDate(properties.snappedTime, self._dateFormat);
				const time = formatDate(properties.snappedTime, self._timeFormat);
				const dateTime = formatDate(properties.snappedTime, self._datetimeFormat);
				self.openContextMenu('timeline', {
					date: date,
					time: time,
					dateTime: dateTime
				});
			}
			if (properties.what === 'item') {
				const item = self._data.get(properties.item);
				self.openContextMenu('event', item);
			}
			properties.event.preventDefault();
		});

		this._timeline.on('rangechanged', function(properties) {
			self.requestUpdate({
				visibleRange: {
					start: properties.start,
					end: properties.end
				}
			});

			if (properties.byUser) {
				self.userEvent({
					type: 'visibleRangeChange',
					start: properties.start,
					end: properties.end
				});
			}
		});

		this._timeline.on('timechanged', function(properties) {
			let timeMarkerKey = properties.id;
			let timeMarker = self._timeMarkers[timeMarkerKey];
			const date = formatDate(properties.time, self._dateFormat);
			const time = formatDate(properties.time, self._timeFormat);
			const dateTime = formatDate(properties.time, self._datetimeFormat);

			self.userEvent({
				type: 'timeMarkerChange',
				key: timeMarkerKey,
				old: timeMarker,
				data: _.extend({}, timeMarker, {
					datetime: dateTime,
					date: date,
					time: time
				})
			});

			let mutation = _.set(
				{}, 
				['timeMarkers', timeMarkerKey],
				_.extend(
					{}, 
					timeMarker, 
					{
						datetime: dateTime,
						date: date,
						time: time
					}
				)
			);

			self.requestUpdate(mutation);
		});


		this._timeline.on('markerchanged', function(properties) {
			let timeMarkerKey = properties.id;
			let timeMarker = self._timeMarkers[timeMarkerKey];

			self.userEvent({
				type: 'timeMarkerTitleChange',
				key: timeMarkerKey,
				old: timeMarker,
				data: _.extend({}, timeMarker, {
					title: properties.title
				})
			});

			let mutation = _.set({}, ['timeMarkers', timeMarkerKey, 'title'], properties.title);
			self.requestUpdate(mutation);
		});
	});

	return div;
};

TimelineViewRenderer.prototype.doUpdate = function(data, changes, updateID) {
	if (this.hasChangedIn(changes, 'options')) {
		this._timeline.setOptions(data.options);
	}

	if (this.hasChangedIn(changes, 'groups')) {
		let groups = _.map(data.groups, (group, key) => ({id: key, ...group}));
		this._timeline.setGroups(groups);
	}

	if (this.hasChangedIn(changes, 'events')) {
		let events = _.map(data.events, (event, key) => ({id: key, ...event}))
		this._data = new visData.DataSet(events);
		this._timeline.setItems(this._data);
	}

	if (_.isDate(data.visibleRange.start) && _.isDate(data.visibleRange.end)) {
		let currentRange = this._timeline.getWindow();

		if (	currentRange.start.getMilliseconds() !== data.visibleRange.start.getMilliseconds()
			|| 	currentRange.end.getMilliseconds() !== data.visibleRange.end.getMilliseconds()) 
		{
			this._timeline.setWindow(data.visibleRange.start, data.visibleRange.end, {animation: {easingFunction: 'linear', duration: 100}});
		}
	}

	if (this.hasChangedIn(changes, 'timeMarkers')) {
		this.syncTimeMarkers(data.timeMarkers);
	}
};

TimelineViewRenderer.prototype.syncTimeMarkers = function(timeMarkers) {
	// remove all timeMarkers
	_.forEach(
		this._timeMarkers,
		(marker, key) => {
			this._timeline.removeCustomTime(key);
		}
	)

	this._timeMarkers = {};

	_.forEach(
		timeMarkers,
		(marker, key) => {
			this._timeline.addCustomTime(marker.datetime || marker.date, key);
			if (_.isString(marker.title)) {
				this._timeline.setCustomTimeMarker(marker.title, key, marker.editable);
			}
			this._timeMarkers[key] = marker;
		}
	)
}

module.exports = TimelineViewRenderer;

