import APIClientAbstract from "core/src/api-client-abstract";
import Log from "utils/src/log";
import deferredPromise from "core/src/utils/deferred-promise";
import {def, validate} from 'utils/src/validation';
import {joinPath} from 'core/src/utils/legacy';
import {get, isPlainObject} from 'lodash';
import Socket from "core/src/utils/socket";
import Error from 'utils/src/error';

const log = Log.instance("client/core/api");

export default class APIClient extends APIClientAbstract{
	io(...args) {
		let socket = new Socket();
		return socket.io(...args);
	}

	setToken(token) {
		super.setToken(token);
	}

	/**
	 * Sends a request (with authentication headers) to the API.
	 * @param {string} uri The endpoint to send the request to.
	 * @param {string} [method] The HTTP method to use. Defaults to GET.
	 * @param {object} [data] The payload data for the endpoint.
	 * @returns {Promise}
	 */
	_executeRequest(uri, method = 'GET', data = {}) {
		let promise = new Promise((resolve, reject) => {
			let check = validate({
				uri: [uri, 'isString'],
				method: [method, 'isString'],
				data: [data, 'isObject', {default: undefined, warn: def(data)}]
			}, "Could not execute " + method + " request to " + uri + '.');
			if (!check.isValid()) {
				return reject(check.createError());
			}
			const valid = check.getValue();

			data = valid.data;
			let url = joinPath(this.config.server, 'api', valid.uri);

			if (!valid.method.match(/get/i)) {
				$.ajaxSetup({
					contentType: 'application/json',
				});
				data = JSON.stringify(data);
			}

			let request = $.ajax({
				type: valid.method,
				url,
				crossDomain: true,
				timeout: this.config.requestTimeoutMs,
				xhrFields: {
					withCredentials: true
				},
				data,
				dataType: 'json'
			});

			request.fail(error => {
				reject(this.getResponseError(error));
			});

			request.done(response => {
				resolve(response);
			});
		});
		return promise;
	}

	getResponseError(response) {
		if (!isPlainObject(response.responseJSON)) {
			let message = `Invalid server response`;
			if(response.statusText) {
				if(response.statusText === 'timeout') {
					message = 'Server timed out.';
				} else {
					message += ` (${response.statusText})`;
				}
			}
			// Server should always provide a json response on errors.
			return new Error(message, response);
		}

		const error = get(response, 'responseJSON.error', "An error occurred.")
		return new Error(error);
	}

	/**
	 * Upload a file from FormData
	 * @param formData
	 * @return {*}
	 */
	upload(file) {
		let formData = new FormData();
		formData.append("file", file);
		let promise = new Promise((resolve, reject) => {
			let url = joinPath(this.config.server, '/api/file/create');
			this.loadingRequestStarted();
			let request = $.ajax({
				type: 'POST',
				url: url,
				processData: false,
				contentType: false,
				crossDomain: true,
				beforeSend: (xhr) => {
					if (get(this, 'config.environment') !== undefined) {
						xhr.setRequestHeader('X-environment', this.config.environment);
					}
				},
				data: formData,
				dataType: 'json',
				xhrFields: {
					withCredentials: true
				},
			});
			request.always(() => {
				this.loadingRequestFinished();
			});
			request.fail(error => {
				log.error('API Upload Error' + url);
				reject(this.handleError(this.getResponseError(error)));
			});
			request.done(response => {
				resolve(response.data);
			});
		});
		return deferredPromise(promise);
	};

}
