var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "treeselect",
    _vm._b(
      {
        key: _vm.renderIndex,
        ref: "self",
        attrs: { disabled: _vm.disabled, loadOptions: _vm.onLoadOptions },
        on: { select: _vm.onSelect },
        scopedSlots: _vm._u([
          {
            key: "value-label",
            fn: function ({ node }) {
              return _c("div", {
                domProps: {
                  innerHTML: _vm._s(node.raw.selectedLabel || node.raw.label),
                },
              })
            },
          },
        ]),
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      },
      "treeselect",
      _vm.schema,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }