import Router from "client/src/router";
import APIClient from "client/src/api-client";
import ApiClientAbstract from "core/src/api-client-abstract";
import Session from "client/src/session";
import ISession from "core/src/i-session";
import ViewManager from "client/src/view-manager";
import setupDefaultCoreDependencies from 'core/src/default-dependencies';
import Dependencies from "core/src/dependencies";
import IFactory from "core/src/i-factory";
import registerDefaultViews from "client/src/views/all";

export default function setupDefaultClientDependencies(dependencies, nodeSafe = false) {
	dependencies = dependencies || new Dependencies();

	// Client-specific dependencies required in Core
	dependencies.setDefault(ApiClientAbstract, ()=>new APIClient(dependencies));
	dependencies.setDefault(ISession, ()=>new Session(dependencies));

	// Add core dependencies
	setupDefaultCoreDependencies(dependencies, nodeSafe);

	// Append client dependencies
	let factory = dependencies.get(IFactory);
	registerDefaultViews(factory, nodeSafe);

	// Defaults for client
	dependencies.setDefault(Router, ()=>new Router());
	dependencies.setDefault(ViewManager, ()=>new ViewManager(dependencies));

	return dependencies;
}
