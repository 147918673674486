const CodeEvaluator = require('core/src/code-evaluator');

const _ = require('core/src/utils/legacy');
const md5 = require('md5');
const stringFormat = require('string-format');
const Language = require('core/src/language').default;
const t = require('core/src/language').t;
const Pivot = require('quick-pivot');


/**
 * Wrapper for string-format that creates a function if only the template was provided.
 * @return {*}
 */
const codeEvaluatorStringFormat = function() {
	let args = Array.from(arguments);
	if(args.length === 1) {
		return function() {
			let finalArgs = Array.from(arguments);
			finalArgs.unshift(args[0]);
			return codeEvaluatorStringFormat.apply({}, finalArgs);
		};
	}
	return stringFormat.apply({}, args);
};

const createDefaultCodeEvaluator = function(dependencies) {
	let translate = t;
	if(dependencies) {
		const language = dependencies.get(Language);
		translate = function(key, options) { return language.translate(key, options); }
	}

	const evaluator = new CodeEvaluator();
	evaluator.setConfig({
		failOnInvalidProperty: false,
		expressionSets: {
			'path': {
				VariableDeclaration: true,
				MemberExpression: true,
				Literal: true,
				Identifier: {
					map: true,
					flatten: true,
					translate: true,
					t: true, // alias
					evaluate: true
				},
				CallExpression: true
			},
			'full': undefined,
			'none': {}
		}
	});

	// Splits a string into an array of strings of max length. Word separator is space, paragraph separator is \n
	let splitStringIntoLines = (string, rowMaxLength = 50) => {
		return _.flow([
			(string) => _.split(string, '\n'),
			(paragraphs) => _.map(paragraphs, (paragraph) => _.split(paragraph, ' ')),
			(paragraphs) => _.flatMap(
				paragraphs,
				(paragraph) => _.reduce(
					paragraph,
					(res, word) => {
						let line = _.size(res) - 1;
						if ((_.size(res[line]) + _.size(word) + 1) > rowMaxLength) {
							res.push('');
							line += 1;
						}
						res[line] = res[line] + (_.size(res[line]) ? ' ' : '') + word;
						return res;
					},
					['']
				)
			)
		])(string)
	};

	// Add lodash to evaluator functions
	_.forEach(_, function(func, i) {
		if(_.isFunction(func)) {
			evaluator.setPublic(i, func);
		}
	});

	evaluator.setPublic('_', _);
	evaluator.setPublic('addColumn', _.addColumn);
	evaluator.setPublic('arrayToTable', _.arrayToTable);
	evaluator.setPublic('btoa', function(value) { return btoa(value); }); // Closure because btoa cannot be called with btoa.apply(this, [value])
	evaluator.setPublic('cellsToRows', _.cellsToRows);
	evaluator.setPublic('Console', () => console);
	evaluator.setPublic('console', console);	
	evaluator.setPublic('formatString', codeEvaluatorStringFormat);
	evaluator.setPublic('isoDate', _.isoDate);
	evaluator.setPublic('Math', () => Math);
	evaluator.setPublic('md5', md5);
	evaluator.setPublic('nodeToCreateStatement', _.nodeToCreateStatement);
	evaluator.setPublic('pivot', (...params) => (new Pivot(...params)));
	evaluator.setPublic('pivotDataToObjects', _.pivotDataToObjects);
	evaluator.setPublic('relationToCreateStatement', _.relationToCreateStatement);
	evaluator.setPublic('splitIntoLines', splitStringIntoLines);
	evaluator.setPublic('t', translate); // alias
	evaluator.setPublic('toDate', (value) => (new Date(value)));
	evaluator.setPublic('translate', translate);

	evaluator.setAdvancedFunction('evaluateString', (evaluator, scopes, value) => {
		// Evaluate a string inside an evaluated string. Allows nesting an evaluation and evaluation of string variables.
		const result = evaluator.evaluate('let evaluated = '+value, scopes[0], {expressionSet: 'full'});
		return result['evaluated'];
	});

	return evaluator;
};

module.exports = createDefaultCodeEvaluator;
