const _ = require('core/src/utils/legacy');
const log = require('core/src/log').instance('trigger');
const CodeEvaluator = require('core/src/code-evaluator');
const ApplicationInfo = require('core/src/application-info').default;
const IFactory = require('core/src/i-factory').default;
const deferredPromise = require('core/src/utils/deferred-promise');
const Profiler = require('utils/src/profiler');
const {writable} = require('core/src/utils/read-only');
const Language = require('core/src/language').default;
const ActivityLog = require('core/src/activity-log').default;

const Trigger = function(dependencies, initData) {
	this._dependencies = dependencies;

	this.properties = {};
	this.parameters = {};
	this.parameterMeta = {};
	this.sourceFunction = undefined;
	this.targetFunction = undefined;
	this.targetFunctionSpec = undefined;
	this.input = {};
	this.output = {};
	this.history = [];

	this.setParameter('$_path', '(%)._path');

	if (initData !== undefined) {
		this.init(initData);
	}

	this.codeEvaluator = dependencies.get(CodeEvaluator);
	this.factory = dependencies.get(IFactory);
	this.fti = dependencies.get(FTI);
	this.appInfo = dependencies.get(ApplicationInfo);
	this.language = dependencies.get(Language);
	this.activityLog = dependencies.get(ActivityLog);
};
// ***************
// STATICS
// ***************

Trigger.ExecutionMethod = {
	NEW_INSTANCE: 'new_instance',
	UPDATE: 'update',
	BROADCAST: 'broadcast'
};

Trigger.TargetInstance = {
	NEW			: '_new',
	PREVIOUS	: '_previous',
	ALL			: '_all'
};

Trigger.Event = {
	Out: {
		ERROR: 'TriggerError'
	}
};

Trigger.isDataMappingExpression = function (expression) {
	if (_.isString(expression) && expression.match(/\(\%[\w\s-]*?\)/)) {
		return true;
	}

	return false;
};

// ***************
// METHODS
// ***************

Trigger.prototype.init = function(initData) {
	if (!_.isPlainObject(initData)) {
		return;
	}

	let initialized = false;

	if (_.has(initData, 'id')) {
		this.id = initData.id;
		initialized = true;
	}
	if (_.has(initData, 'properties')) {
		this.setProperties(initData.properties);
		initialized = true;
	}
	if (_.has(initData, 'parameters')) {
		this.setParameters(initData.parameters);
		initialized = true;
	}
	if(_.has(initData, 'sourceFunction')) {
		this.sourceFunction = initData.sourceFunction;
		initialized = true;
	}
	if(_.has(initData, 'targetFunction')) {
		this.setTargetFunction(initData.targetFunction);
		initialized = true;
	}
	if(_.has(initData, 'parameterMeta')) {
		this.parameterMeta = _.cloneDeep(initData.parameterMeta);
	}
	if(_.has(initData, 'input')) {
		this.input = _.cloneDeep(initData.input);
	}
	if(_.has(initData, 'output')) {
		this.output = _.cloneDeep(initData.output);
	}
	if(_.has(initData, 'history')) {
		this.history = _.cloneDeep(initData.history);
	}

	if (!initialized) {
		this.loadFromJson(initData);
	}

	return this;
};

Trigger.prototype.getGlobalTriggerData = function() {
	return this.fti.getGlobalData();
};

Trigger.prototype.clone = function() {
	return new Trigger(
		this._dependencies,
		{
			id: this.id,
			properties: this.properties,
			parameterMeta: this.parameterMeta,
			parameters: this.parameters,
			sourceFunction: this.sourceFunction,
			targetFunction: this.targetFunction,
			targetFunctionSpec: this.targetFunctionSpec,
			input: this.input,
			output: this.output,
			history: this.history
		}
	);
};

/**
 * Communicate that an error has happened.
 * @param {object|string} error	   The error object or message.
 */
Trigger.prototype.error = function(error) {
	if(_.isString(error)) {
		error = new _.Error(error);
	}
	var triggerError = new _.Error({
		message: this.language.translate('encountered-error', {name: this.toString()}),
		public: true,
		originalError: error,
		data: {
			triggerID: this.id,
			targetFunction: this.targetFunction
		}
	});

	log.error(triggerError.message, triggerError);

	this.eventOut(Trigger.Event.Out.ERROR, {
		error: triggerError,
		container: {
			width: 600,
			height: 400
		}
	});
};

/**
 * Sends an event to the FTI.
 * @param type
 * @param event
 */
Trigger.prototype.eventOut = function(type, event) {
	this.fti.eventOut(type, event, this);
};

Trigger.prototype.loadFromJson = function(triggerInit, targetFunction) {
	var parameters = {},
		properties = {};

	if (!_.isPlainObject(triggerInit)) {
		return false;
	}

	_.forOwn(triggerInit, function(value, key) {
		if (Function.isValidParameter(key)) {
			parameters[key] = value;
		}
		else {
			properties[key] = value;
		}
	});

	this.setParameters(parameters).setProperties(properties);

	if (targetFunction !== undefined) {
		this.setTargetFunction(targetFunction);
	}

	return this;
};

Trigger.prototype.setTargetFunction = function(targetFunction) {
	if (_.isPlainObject(targetFunction) || (_.isStringOrNumber(targetFunction)) || targetFunction instanceof Function) {
		this.targetFunction = targetFunction;
		this.targetFunctionSpec = targetFunction;
	}
	else {
		log.error('Trigger.setTargetFunction: targetFunction should be plainObject function initialization structure', targetFunction);
	}
	return this;
};

Trigger.prototype.setParameter = function(parameter, mapping) {
	var parameterDefinition = Function.getParameterDefinition(parameter);
	if(parameterDefinition !== null) {
		if(parameterDefinition.meta) {
			if(!_.isObject(this.parameterMeta[parameterDefinition.name])) this.parameterMeta[parameterDefinition.name] = {};
			var currentValue = this.parameterMeta[parameterDefinition.name][parameterDefinition.meta];
			if(_.isPlainObject(mapping) && _.isPlainObject(mapping)) {
				this.mergeInput(mapping, currentValue);
			} else {
				Function.set(this.parameterMeta[parameterDefinition.name], parameterDefinition.meta, mapping);
			}
		} else {
			this.parameters[parameter] = mapping;
		}
	} else{
		log.error('Trigger.setParameter: parameter is not valid', parameter);
	}
	return this;
};

Trigger.prototype.setParameters = function(parameters) {
	var self = this;
	if (_.isPlainObject(parameters)) {
		_.forOwn(parameters, function(mapping, parameter) {
			self.setParameter(parameter, mapping);
		});
	}

	return this;
};

Trigger.prototype.getParameters = function() {
	return this.parameters;
};

Trigger.prototype.getParameterMetaData = function(path) {
	var value = undefined;
	if(path === undefined) {
		value = this.parameterMeta;
	} else {
		if(_.isArray(path)) path = path.join('.');
		value = _.get(this.parameterMeta, path);
	}

	return value;
};

Trigger.prototype.setProperties = function(properties) {
	var self = this;
	if (_.isPlainObject(properties)) {
		_.forOwn(properties, function(value, key) {
			self.properties[key] = value;
		});
	}

	return this;
};

Trigger.prototype.getProperties = function() {
	return this.properties;
};

Trigger.prototype.setInput = function(inputData) {
	if (_.isPlainObject(inputData)) {
		this.input = inputData;
	}
	else if (inputData !== undefined){
		this.input = {};
	}
	return this;
};

Trigger.prototype.getInput = function() {
	return this.input;
};

/**
 * Evaluates a value (expression) with the given inputData (event).
 * @param value							The value to evaluate.
 * @param {object} inputData			The event input data.
 * @param {boolean} [inputAsContext]	If set to true, the input properties will be merged directly into the context.
 * 										Each property can then be accessed directly by its name, without preceding `_event.`.
 * @param {string} [expressionSet]		The enabled expression set to use in evaluation.
 * @returns {*}
 */
Trigger.prototype.evaluate = function (value, inputData, inputAsContext, expressionSet) {
	var result;

	if (!_.isString(value)) {
		return value;
	}

	// Try to evaluate parameter value as code.

	// Trim code
	var code = value.trim();

	var codeEvaluator = this.codeEvaluator;
	if (!(codeEvaluator instanceof CodeEvaluator)) {
		log.warn("No CodeEvaluator registered for triggers. Cannot evaluate code.");
		return value;
	}

	// Setup context
	code = CodeEvaluator.replaceCodePlaceholders(code, {
		'(%)': '_event',
		'(@)': '_global'
	});

	const context = {
		_global: this.fti.getGlobalData()
	};

	// Merge input data into context if requested
	if (inputAsContext === true) {
		_.extend(context, inputData);
	}

	context['_event'] = inputData;

	// Try to evaluate the code
	try {
		if (code === "") code = "undefined";
		result = codeEvaluator.evaluate('var x = ' + code, context, {
			arrayExpressions: true,
			expressionSet: expressionSet
		})['x'];
		return result;
	}
	catch (e) {
		if (expressionSet === 'full' || /^e(?:valuate)?\(.*\)$/.exec(code)) {
			throw new _.Error({
				message: this.language.translate("Could not evaluate parameter value '{{value}}'.", {value}),
				originalError: e
			});
		}

		return value;
	}
};

Trigger.prototype.matchValueToParameterType = function(parameter, value) {
	if(value === undefined) {
		return value;
	}

	if (_.isArray(value) && (value.length > 0) && (Function.getParameterType(parameter) == Function.ParameterType.OBJECT)) {
		value = value[0];
	}
	else if (!_.isArray(value) && (Function.getParameterType(parameter) == Function.ParameterType.ARRAY)) {
		value = [value];
	}

	return value;
};

Trigger.prototype.mapInputToParameters = function(inputData) {
	var self = this,
		input,
		output,
		parameters;

	input = this.setInput(inputData).getInput();
	parameters = this.getParameters();
	output = {};

	if (!_.isEmpty(parameters)) {
		_.forOwn(parameters, function(parameterValue, parameter) {
			var paramDef = Function.getParameterDefinition(parameter);
			if(paramDef === null) {
				console.error('Should not be here: parameter is not valid', parameter);
				return;
			}

			var outputKey = Function.parameterToKey(parameter);

			var evaluate = _.get(self.getParameterMetaData(outputKey), 'evaluate');
			var expressionSet = Function.getEvaluationLevel(evaluate);

			var value = undefined;
			try {
				value =  self.evaluate(parameterValue, input, false, expressionSet);
			}
			catch (e) {
				self.error(e);
			}

			value = self.matchValueToParameterType(parameter, value);
			if(_.isPlainObject(value)) {
				// this is so that the receiving Function knows to replace everything at this path (outputKey) with the given value
				value[Function.VALUE_OBJECT] = true;
			}
			output[outputKey] = value;
		});
	}
	this.output = output;
	return this;
};

Trigger.prototype.getOutput = function() {
	return this.output;
};

/**
 * Set the history to this Function.
 * @param {array} history Array of objects containing 'instance' and 'type' properties.
 * @returns {undefined}
 */
Trigger.prototype.setHistory = function(history) {
	if(!_.isArray(history)) {
		log.warn("Invalid history.");
		history = [];
	} else {
		for(var i = history.length - 1; i >= 0; i--) { // reverse to not mess up indexes
			if(!_.isStringOrNumber(history[i].instance) || !_.isString(history[i].type)) {
				log.warn("Invalid history entry", history[i]);
				history.splice(i, 1);
			}
		}
	}
	this.history = history;
};

/**
 * Get the history to this Function.
 * @returns {array}
 */
Trigger.prototype.getHistory = function() {
	return this.history;
};

Trigger.prototype.executeTargetFunction = function(input, instanceID) {
	const self = this;
	const promise = new Promise((resolve, reject) => {
		this.loadTargetFunction()
			.done(function(targetFunction) {
				if (targetFunction) {
					targetFunction.setHistory(self.getHistory());
					if(_.def(instanceID)) {
						targetFunction.setInstanceID(instanceID, true);
					}
					var meta = _.cloneDeep(self.parameterMeta);
					self.removeLocalMetaProperties(meta);
					const evaluatedMeta = self.evaluateMetaProperties(meta); // <-- new
					targetFunction.setParameterMetaData(evaluatedMeta);
					targetFunction.execute(input)
						.done(function (result) {
							resolve(targetFunction);
						});
				}
			})
			.fail(function(err) {
				reject(err);
			});
	});
	return deferredPromise(promise);
};

/**
 * Evaluate meta properties
 * @returns {object} Evaluated meta properties
 */

Trigger.prototype.evaluateMetaProperties = function(meta) {
	let self = this;
	// Get input data
	let inputData = self.getInput();
	let _evaluate = function(value) {

		if (_.isPlainObject(value)) {
			let ev = {};
			for(let key in value) {
				ev[key] = _evaluate(value[key]);
			}
			return ev;
		}
		// The evaluation should use the path expression set (which is limited but includes the inline evaluation function).
		return self.evaluate(value, inputData, false, 'path');
	};
	return _evaluate(meta);
}

/**
 * Removes meta properties that are used within the Trigger and should not be passed to the target Function.
 * @param meta
 */
Trigger.prototype.removeLocalMetaProperties = function(meta) {
	var localMetaProperties = {
		evaluate: true
	};
	for(var path in meta) {
		for(var property in localMetaProperties) {
			delete meta[path][property];
		}
	}
};

Trigger.prototype.getExecutionMethod = function() {
	var method = _.get(this.parameters, '$_method');
	var defaultMethod = Trigger.ExecutionMethod.NEW_INSTANCE;

	if(!_.isString(method)) {
		return defaultMethod;
	}
	method = method.toLowerCase();
	for(var i in Trigger.ExecutionMethod) {
		if(method === Trigger.ExecutionMethod[i]) {
			return Trigger.ExecutionMethod[i];
		}
	}

	return defaultMethod;
};

/**
 *
 * @param {boolean} [forceLoad]	If true, a new function will be created. Defaults to false.
 * @returns {*}
 */
Trigger.prototype.loadTargetFunction = function(forceLoad = false) {
	const self = this;
	const promise = new Promise((resolve, reject) => {
		if(forceLoad === true && this.targetFunction instanceof Function) {
			this.targetFunction = this.targetFunctionSpec;
		}
		this.factory.loadFunction(this.targetFunction)
			.done(function(func) {
				self.targetFunction = func;
				resolve(func);
			})
			.fail(function(err) {
				reject(new _.Error(self.language.translate('Could not load target Function.'), err));
			});
	});
	return deferredPromise(promise);
};

Trigger.prototype.getTargetInstanceDescription = function() {
	const self = this;
	const promise = new Promise((resolve, reject) => {
		this.loadTargetFunction()
			.done(function(func) {
				// 1. Function-defined instance property (non-overridable)
				var targetInstance = func.getProperty('_instance');
				if(_.def(targetInstance)) {
					resolve(targetInstance);
					return;
				}

				// 2. Trigger-defined instance parameter
				targetInstance = _.get(self.getOutput(), '_instance');
				if(_.def(targetInstance)) {
					resolve(targetInstance);
					return;
				}

				// 3. Function-defined instance parameter
				targetInstance = func.getParameter('$_instance');
				if(_.def(targetInstance)) {
					resolve(targetInstance);
					return;
				}

				// 4. Backwards compatibility with $_method
				var executionMethod = self.getExecutionMethod();
				if(_.def(executionMethod) && executionMethod !== Trigger.ExecutionMethod.NEW_INSTANCE) {
					if(executionMethod === Trigger.ExecutionMethod.UPDATE) {
						targetInstance = Trigger.TargetInstance.PREVIOUS;
					} else { // broadcast
						targetInstance = Trigger.TargetInstance.ALL;
					}
					resolve(targetInstance);
					return;
				}

				// 5. Default
				resolve(Trigger.TargetInstance.NEW);
			})
			.fail(function(err) {
				reject(new _.Error(self.language.translate('Could not get target instance description.'), err));
			});
	});

	return deferredPromise(promise);
};

Trigger.prototype.valuesConsideredEqual = function(a, b) {
	return _.valuesConsideredEqual(a,b);
};

Trigger.prototype.matchesEvent = function(event) {
	const context = event;
	for(let i in this.properties) {
		// Don't try to match uuid
		const uuid = _.get(this.appInfo, 'stores.application.uuidProperty');
		if(i === uuid) continue;

		let key = i;
		let value = this.properties[i];
		try {
			key = this.evaluate(i, event, true, 'full');
			value = this.evaluate(this.properties[i], context, false, 'full');
		}
		catch (e) {
			// keep the defaults
		}

		if(!this.valuesConsideredEqual(key, value)) {
			return false;
		}
	}

	return true;
};

Trigger.prototype.logExecution = function() {
	this.activityLog.add(this);
}

/**
 * Alter global information of trigger event.
 * @param {object} trigger
 * @return {*}
 */
Trigger.prototype.getTriggerLog = function() {
	const clone = this.clone();

	if (_.get(clone, 'input._global.apiKeys')) {
		const apiKeys = clone.input._global.apiKeys;
		_.forEach(apiKeys, (value, key) => apiKeys[key] = 'Private');
	}

	return clone;
};

Trigger.prototype.execute = function(inputData) {
	const profilerExecute = Profiler.start('trigger.execute');
	let execStart = new Date().getTime();

	const self = this;

	const promise = new Promise((resolve, reject) => {
		/*
		Safari cannot apply cloneDeep on Proxies without destroying any function in there, so we
		need to clone a writable version of the input data. We probably got top-writable read-only Proxy from Function,
		so from the second level all keys will be read-only (hence the `1` argument to `writable`).
		 */
		inputData = _.cloneDeep(writable(inputData, 1)) || {};

		// Add globals if not present
		if(!('_global' in  inputData)) {
			inputData._global = this.fti.getGlobalData();
		}

		if (!_.def(this.targetFunction)) {
			log.error('Trigger.execute: targetFunction not set', this.targetFunction);
			return reject(inputData).promise();
		}
		else {
			this.mapInputToParameters(inputData);

			let todo;
			this.getTargetInstanceDescription()
				.done(function(targetInstance) {

					let from = self.sourceFunction ? ` from ${self.sourceFunction}` : '';
					log.info(`Executing ${self}${from} to ${self.targetFunction}`, self.getTriggerLog());

					const functionID = self.targetFunction.getId();
					switch (targetInstance) {
						case Trigger.TargetInstance.NEW:
							todo = self.executeTargetFunction(self.getOutput());
							break;
						case Trigger.TargetInstance.PREVIOUS:
							todo = self._updatePrevious(functionID);
							break;
						case Trigger.TargetInstance.ALL:
							todo = self._updateBroadcast(functionID);
							break;
						default:
							const allowCreation = !_.hasBooleanValue(_.get(self.getParameters(), '$_instanceUpdateOnly'), true);
							todo = self._updateOrCreate(self.targetFunction, targetInstance, allowCreation);
							break;
					}
					todo.done(function (response) {
						profilerExecute.stop();
						resolve(response);
					});
					todo.fail(function (err) {
						reject(err);
						self.error(err);
					});
				})
				.fail(function(err) {
					const error = new _.Error(self.language.translate('Could not execute trigger.'), err);
					reject(error);
					self.error(error);
				});
		}
	});

	promise.then(() => {
		let execEnd = new Date().getTime();

		self.activityLog.add({
			short_message: 'Graphileon Trigger executed',
			trigger_id: this.id,
			trigger_uuid: _.get(self, 'properties.uuid'),
			trigger_start: execStart,
			trigger_end: execEnd,
			trigger_duration: execEnd - execStart,
			sourceFunction_id: _.get(self, 'sourceFunction.id'),
			sourceFunction_uuid: _.get(self, 'sourceFunction.properties.uuid'),
			targetFunction_id: _.get(self, 'targetFunction.id'),
			targetFunction_uuid: _.get(self, 'targetFunction.properties.uuid'),
			session_id: _.get(self, 'fti.session.id'),
			user_uuid: _.get(self, 'input._global.user.uuid'),
			user_id: _.get(self, 'input._global.user.id')
		});
	});
	
	return deferredPromise(promise);
};

Trigger.prototype._updatePrevious = function(functionID) {
	const promise = new Promise((resolve, reject) => {
		// Go through history
		var history = this.getHistory();
		var instance = _.find(history, {id: functionID});
		if(instance !== undefined) {
			resolve(this.fti.updateFunctionInstances({instanceID: instance.instance}, this.getOutput()));
		}
	});

	return deferredPromise(promise);
};

Trigger.prototype._updateBroadcast = function(functionID) {
	const promise = new Promise((resolve, reject) => {
		resolve(this.fti.updateFunctionInstances({id: functionID}, this.getOutput()));
	});
	return deferredPromise(promise);
};

Trigger.prototype._updateOrCreate = function(targetFunc, instanceID, allowCreation) {
	const self = this;

	const promise = new Promise((resolve, reject) => {
		allowCreation = allowCreation === true;

		var functionID = undefined;
		if (_.isStringOrNumber(targetFunc)) {
			functionID = targetFunc;
		} else if (_.isPlainObject(targetFunc)) {
			functionID = targetFunc.id;
		} else if (targetFunc instanceof Function) {
			functionID = targetFunc.getId();
		}

		// Check if function instance already exists
		var existing = this.fti.findFunctionInstances({instanceID: instanceID});
		if(Object.keys(existing).length === 0) { // instance was not found
			if(!allowCreation) {
				log.info(self.getTriggerLog() + ": update-only trigger, target instance not open. Doing nothing.");
				resolve();
				return;
			}
			var create = this.executeTargetFunction(this.getOutput(), instanceID);
			create.done(function(func) {
				resolve(func);
			});
			create.fail(function(err){
				let error = new _.Error(self.language.translate('Could not create function instance.'), err);
				self.error(error);
				reject(error);
			});
		} else { // instance was found
			var func = existing[Object.keys(existing)[0]];
			if(func.getId() !== functionID) {
				reject(new _.Error({
					message: this.language.translate("Could not create function instance '{{instanceID}}' for Function {{functionID}}.", {instanceID, functionID}),
					originalError: new _.Error(this.language.translate("A Function instance named '{{instanceID}}' already exists: '{{func}}'.", {instanceID, func}))
				}));
			}
			resolve(self.fti.updateFunctionInstances({id: functionID, instanceID: func.getInstanceID()}, this.getOutput()));
		}
	});

	return deferredPromise(promise);
};

/**
 * @override
 * @returns {string}
 */
Trigger.prototype.toString = function() {
	return `Trigger (id ${this.id}, type '${this.properties.type}}')`;
};

module.exports = Trigger;

// Importing from here allows circular dependencies
const Function = require('core/src/function');
const FTI = require('core/src/fti');
