
import APIClientAbstract from "core/src/api-client-abstract";
import VueRendererComponent from "client/src/renderers/vue-components/vue-renderer-component.vue";
import iaApiMarkdown from "client/src/markdown/iaApi"
import markdownItAttrs from 'markdown-it-attrs'
import markdownItVideo from 'markdown-it-video'


import MarkdownItVue from 'markdown-it-vue'

import './markdown.scss';

export default {
	name: "markdown",
	mixins: [VueRendererComponent],
	components: {
		MarkdownItVue
	},
	data() {
		return {
			markdown: undefined,
			source: undefined,
			options: {
				markdownIt: {
					html: true,
					xhtmlOut: true,
					breaks: true,
					linkify: true,
					emoji: true,
					typographer: true,
				},
				icons: 'font-awesome',
				githubToc: {
					tocFirstLevel: 1,
					tocLastLevel: 6,
					anchorLinkSymbol: '',
				},
				linkAttributes: {}
			}
		}
	},
	watch: {
		markdown( newVal ) {
		}
	},
	methods: {
		onClickLinks(e) {
			let node = e.target;
			// check if the clicked node is child node
			while (node != undefined && node.localName != 'a') {
				node = node.parentNode;
			}
			// check the clicked target if it is a and link is starting with # then handle it
			if (node != undefined && node.attributes.href.value.startsWith('#') ) {
				e.preventDefault();

				const href = node.attributes.href.value.split('#').pop();
				this.$refs.myMarkdownItVue.$el.querySelector(`[name="${href}"], #${href}`).scrollIntoView({
		            behavior: 'smooth'
		        });
				return false;
			}

			return true;
		},
   	},
	mounted() {
		const self = this;
		this.$api = this.dependencies.get(APIClientAbstract);
		this.$refs.myMarkdownItVue.use(iaApiMarkdown, {
			iaApi(args) {
				const inputs = {};
				const iaName = args['ia-name'];
				for ( let key in args ) {
					if ( _.startsWith(key, 'input-') ) {
						_.set(inputs, key.substr(6), args[key]);
					}
				}

				const rand = _.random(0, 1000);
				let tag = `<div id="ia-api-${rand}" class="ia-component ia-component-api" data-ia-name="${iaName}" data-contenteditable="false" data-draggable="true" data-_type="ia-api" contenteditable="false" draggable="true"`;
				_.forEach(inputs, (key, val) => {
					tag += ` data-input-${key}="${val}"`;
				})
				tag += `><i class="fas fa-spin fa-spinner"></i></div>`;
				const request = self.$api.callAPIFunction(iaName, inputs);

				request.done((response) => {
					const element = document.getElementById(`ia-api-${rand}`);
					element.innerHTML = response;
				})
				request.fail((error) => {
					const element = document.getElementById(`ia-api-${rand}`);
					element.innerHTML = error;
				})
				return tag;
			}
		});
		this.$refs.myMarkdownItVue.use(markdownItAttrs)
		this.$refs.myMarkdownItVue.use(markdownItVideo, {
			youtube: { width: 640, height: 390 },
			vimeo: { width: 500, height: 281 },
			vine: { width: 600, height: 600, embed: 'simple' },
			prezi: { width: 550, height: 400 }
		})

		if ( this.source ) {
			this.markdown = document.getElementById(this.source).value;
		}
		this.$refs.myMarkdownItVue.$el.addEventListener(`click`, this.onClickLinks);
	},
	beforeDestroy() {
		this.$refs.myMarkdownItVue.$el.removeEventListener('click', this.onClickLinks);
	},
}
