import Log from 'core/src/log';
import EventInterface from 'core/src/event-interface';
import deferredPromise from 'core/src/utils/deferred-promise';
import ApiClientAbstract from 'core/src/api-client-abstract';
import ISession from 'core/src/i-session';
import {def} from 'utils/src/validation';
import Err from 'utils/src/error';
import {v4 as uuid} from 'uuid';

const log = Log.instance("client/core/session");

export default class Session extends ISession {
	constructor(dependencies, useCookies = true) {
		super();
		this.id = uuid();
		this._eventInterface = new EventInterface();
		this.on = this._eventInterface.getOnMethod();
		this.fire = this._eventInterface.getFireMethod();

		this.user = null;
		this.useCookies = useCookies;

		if(useCookies) {
			this.initFromCookies();
		}

		this.api = dependencies.get(ApiClientAbstract);
		this.api.on(ApiClientAbstract.Event.SESSION_EXPIRED, ()=>{
			if(this.useCookies) {
				this.clearAllCookies();
			}
		});
	}

	initFromCookies() {
		this.getUser();
		this.getToken();
		this.getLanguage();
	}

	/**
	 * Create/modify a cookie
	 * @param {string} name
	 * @param {string} value
	 * @param {int} hours
	 * @returns {undefined}
	 */
	createCookie(name,value,hours) {
		if (hours) {
			var date = new Date();
			date.setTime(date.getTime()+(hours*60*60*1000));
			var expires = "; expires="+date.toGMTString();
		}
		else var expires = "";
		document.cookie = name+"="+value+expires+"; path=/";
	};

	/**
	 * Read a cookie
	 * @param {string} name
	 * @returns {undefined}
	 */
	readCookie(name) {
		var nameEQ = name + "=";
		var ca = document.cookie.split(';');
		for(var i=0;i < ca.length;i++) {
			var c = ca[i];
			while (c.charAt(0)==' ') c = c.substring(1,c.length);
			if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
		}
		return null;
	}

	/**
	 * Erase a cookie
	 * @param {string} name
	 * @returns {undefined}
	 */
	eraseCookie(name) {
		this.createCookie(name,"",-1);
	}

	async clear() {
		this.user = null;
		this.token = null;
		this.language = null;
		try {
			await this.api.logout();
		} catch (err) {
			log.error(err);
		}
		if(this.useCookies) {
			this.clearAllCookies();
		}
	}

	/**
	 * Clear all cookies
	 * @param {string} name
	 * @returns {undefined}
	 */
	clearAllCookies() {
		this.eraseCookie('token');
		this.eraseCookie('language');
	};

	/**
	 * Attempt a user login
	 * @param {string} username
	 * @param {string} password
	 * @param {boolean} [cookies] Store session as cookies?
	 * @returns {Deferred}
	 */
	authenticate(username, password, cookies) {
		let promise = new Promise(async (resolve, reject) => {
			// Already authenticated and no new username/password provided, just resolve with current user
			if(!username && !password && this.token && this.user) {
				return resolve({user: this.user, token: this.token});
			}

			// No user info, but we have a token
			if(!username && !password && this.getToken()) {
				let user = await this.api.getCurrentUser();
				let profiles =  await this.api.getCurrentProfiles();
				user.properties.profiles = profiles;
				user.properties.authenticator = await this.api.getCurrentAuthenticator();

				this.setUser(user);
				return resolve({user, token: this.token});
			}

			if(cookies === undefined) {
				cookies = true;
			}

			if ( ! username || ! password ) {
				this.clearAllCookies();
				return reject(new Err("Username and password required"));
			}

			const request = this.api.login(username, password);
			request.then(async response => {
				if (response.user === undefined) {
					return reject(new Err("Could not log in."));
				}

				const token = response.token;
				this.setToken(token);

				let user = response.user;
				user.properties.profiles =  await this.api.getCurrentProfiles();
				user.properties.authenticator = await this.api.getCurrentAuthenticator();

				this.setUser(user);

				this.fire(Session.Event.LOGIN, {user, token});

				resolve({user, token});
			}).catch(error => {
				const code = error.code;
				const message = error.message;
				this.clear();
				reject(new Err({code: code, message: message}));
			});
		});
		return deferredPromise(promise);
	}




	/**
	 * Sets the user of the current session
	 * @param user
	 */
	setUser(user) {
		this.user = user;
	}

	/**
	 * Sets the language for this session.
	 * @param language
	 */
	async setLanguage(language) {
		this.language = language;
		this.createCookie('language', language, 24);
	}

	/**
	 * Sets the access token for this session.
	 * @param token
	 */
	setToken(token) {
		this.token = token;
		if (this.useCookies) {
			if (token) {
				this.createCookie('token', token, 24);
			} else {
				this.eraseCookie('token');
			}
		}
		this.api.setToken(token);
	}

	getUser() {
		return this.user;
	}

	getToken() {
		if (! this.useCookies) {
			return this.token;
		}
		this.token = this.readCookie('token');
		return this.token;
	}

	getLanguage() {
		this.language = this.readCookie('language') || 'en';
		return this.language;
	}

	/**
	 * @deprecated Use logout() instead.
	 * @returns {*}
	 */
	logOffUser() {
		return this.logout();
	}
	async logout() {
		const user = this.getUser();
		try {
			await this.clear();
		} catch (err) {
			log.error(err);
		}
		this.fire(Session.Event.LOGOUT, user);
	}

	isLoggedIn() {
		return this.user && def(this.user.id);
	}
}
