const _ = require('core/src/utils/legacy');
const Mustache = require('mustache');
const Function = require('core/src/function');
const View = require('core/src/functions/view');

const HtmlView = Function.extend(View, "HtmlView");

let handleDataUpdate = function() {
	this._updateHtml();
};

let handleListsUpdate = function() {
	this._updateHtml();
};

HtmlView.prototype._updateHtml = function() {
	// Read-only shallow clone so we can use the model in Mustache and modify properties first
	const model = this.readModel().$cloneShallow().$writableTop();

	// Shallow clone of lists so we can overwrite key-value pairs
	const lists = _.clone(model.lists);

	if(_.isPlainObject(lists)) {
		_.forEach(lists, (value, key) => {
			lists[key] = View.arraysToTable(value);
		});
	}

	// Set modified lists object on our model clone
	model.lists = lists;

	const html = Mustache.render(model.template, model);

	this.updateModel('html', html);
};

HtmlView.prototype.onUpdate = function(input) {
	this._updateHtml();
};

HtmlView.prototype.onExecute = function(input) {
	this._updateHtml();
	View.prototype.onExecute.call(this, input);
};

/**
 * @override
 * @returns {undefined}
 */
HtmlView.prototype.onInit = function() {
	this.setModelUpdateHandler('data', handleDataUpdate);
	this.setModelUpdateHandler('lists', handleListsUpdate);

	this.setParameters({
		'$data': undefined,
		'$lists': undefined,
		'$template': undefined
	});

	this.setModelValidation({
		template: ['isString']
	});

	// Turn off built-in templating
	this.setTemplating('template', false);
	this.setTemplating('data', false);
	this.setTemplating('html', false);

	// Set update priorities
	this.setUpdatePriorities({
		'template': -1
	});

};

module.exports = HtmlView;
