import VueView from "client/src/vue-view";

export default function registerDefaultViews(factory, nodeSafe = false) {
	// Views
	factory.register(require('core/src/functions/views/table-view'));
	factory.register(require('core/src/functions/views/network-view'));
	factory.register(require('core/src/functions/views/html-view'));
	factory.register(require('core/src/functions/views/properties-view'));
	factory.register(require('core/src/functions/views/search-view'));
	factory.register(require('core/src/functions/views/node-form-view'));
	factory.register(require('core/src/functions/views/relation-form-view'));
	factory.register(require('core/src/functions/views/network-styles-view'));
	factory.register(require('core/src/functions/views/neighbours-view'));
	factory.register(require('core/src/functions/views/grid-view'));
	factory.register(require('core/src/functions/views/login-view'));
	factory.register(require('core/src/functions/views/markdown-view'));
	factory.register(require('core/src/functions/views/diff-view'));
	factory.register(require('core/src/functions/views/ag-grid-view'));
	factory.register(require('core/src/functions/views/yfiles-view'));
	factory.register(require('core/src/functions/views/yfiles-isometric-view'));
	factory.register(require('core/src/functions/views/permission-edit-view'));
	factory.register(require('core/src/functions/views/team-edit-view'));
	factory.register(require('core/src/functions/views/set-password-view'));
	factory.register(require('core/src/functions/views/markdown-edit-view'));
	factory.register(require('core/src/functions/views/ifc-3d-view'));
	factory.register(require('core/src/functions/views/layout-view'));
	factory.register(require('core/src/functions/views/image-map-view'));

	factory.register(require('./calendar-view/calendar-view'));
	factory.register(require('./chart-view/chart-view'));
	factory.register(require('./chartjs-view/chartjs-view'));
	factory.register(require('./form-view/form-view'));
	factory.register(require('./map-view/map-view'));
	factory.register(require('./timeline-view/timeline-view'));
	factory.register(require('./tree-view/tree-view'));

	if(!nodeSafe) {
		factory.register(VueView.extendWith(require('./vue-view-test/vue-view-test.vue').default, 'VueViewTest'));
		factory.register(VueView.extendWith(require('./translate-view/translate-view.vue').default, 'TranslateView'));
		factory.register(VueView.extendWith(require('./input-view/input-view.vue').default, 'InputView'));
	}
}
